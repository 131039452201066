import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Storage } from "aws-amplify";
import { delay } from "bluebird";
import SeparationList from "../SeparationList";
import { AudioSeparationPath } from "const";
import { IsTaskRunning, GetSeparationKey, } from "utils";
import SoundSeparationDialog from "components/Dialogs/SoundSeparationModal";
import { useLocation } from "react-router-dom";
import PlayerModal from "components/Dialogs/PlayerModal";
import { useDispatch, useSelector } from "react-redux";
import { addSeparation, removeSeparation } from "../../redux/slices/AudioFilesSlice";
import { SeparationStatus } from "const";
import { GetPresignedUrlForFile } from "utils";
import { UpdatePendingSeparation } from "utils";
import { areDownloadsAllowed } from "APIHelpers";

function FileSeparations() {
  const location = useLocation();
  const file = location.state?.file;
  const [stemUrls, setStemUrls] = useState([]);
  const [openSeparationDialog, setOpenSeparationDialog] = useState(false);
  const [openPlayerModal, setOpenPlayerModal] = useState(false);

  const dispatch = useDispatch();

  // Safely access file data using conditional logic
  const fileData = useSelector((state) => file && file.key && state.audioFiles[file.key]);

  // Should only happen if using the browser's "back" button to return to the file separation
  // for a file that's already been deleted.  It might be better to somehow remove that history entry.
  if (!fileData) {
    return null;
  }

  const separationList = fileData.separations;

  // Delete a separation (including all stem files) in S3
  const handleDelete = async (event) => {
    try {
      const { method, model } = event;
      const folderPath = `${AudioSeparationPath}${file.metadata.artist}/${file.metadata.album}/${file.metadata.title}/${method}/${model}`;

      // CONSIDER: could store full key (or just filename, then combine with path that serves as the key for its entry in Redux)
      // S3 does not support a recursive delete, in one go, of all files under a given path.

      // List all S3 objects under the specified folder path
      const listedObjects = await Storage.list(folderPath, {
        level: "private",
        pageSize: "ALL",
      });

      if (listedObjects.length === 0) {
        console.log("No files found in the specified folder.");
        return;
      }

      // Delete each file in the specified folder
      await Promise.all(
        listedObjects.results.map(async (object) => {
          const { key } = object;
          await Storage.remove(key, { level: "private" });
        })
      );
      dispatch(
        removeSeparation({
          type: "REMOVE_SEPARATION",
          fullSeparationKey: folderPath,
          sourceKey: file.key,
        })
      );

    } catch (error) {
      console.error("Error deleting separation:", error);
    }
  };

  const shouldLoadStems = async (stems) => {
    if (await areDownloadsAllowed()) {

      for (const stem of stems) {
        const keyFound = stemUrls.some((url) =>
          decodeURIComponent(url).includes(stem.key)
        );

        if (!keyFound) {
          return true; // If any stem in this separation is not already loaded, load all of them.
        }
      }
    }

    return false; // All of the stems are already loaded..  no need to reload.
  };

  // Load a new set of stems
  const loadStems = async (stems) => {
    if (await shouldLoadStems(stems)) {
      try {
        const urls = await getPresignedStemUrls(stems);
        setStemUrls(urls);
      } catch (error) {
        if (error.response.status === 403) {
          // show download/streaming quota exceeded dialog
          console.error("Forbidden: Quota exceeded:", error);
        } else {
          console.error("Error determining if a download is allowed:", error);
        }
      }
    }
  };

  // Load stems for this particular separation
  const handleClick = async (filteredStems) => {
    await loadStems(filteredStems);
    setOpenPlayerModal(true);
    return;
  };

  const handleSeparationCancel = () => {
    setOpenSeparationDialog(false);
  };

  const handlePlayerClose = () => {
    setOpenPlayerModal(false);
  };

  const handleSeparationClose = async (pendingSeparation) => {
    // add new pending separation to redux
    const separationKey = `${GetSeparationKey(file.metadata)}/${pendingSeparation.method}/${pendingSeparation.model}`;
    const separation = CreateReduxSeparationObject(
      separationKey,
      pendingSeparation.key,
      pendingSeparation.taskArn,
    );
    dispatch(
      addSeparation({
        type: "ADD_SEPARATION",
        newSeparation: {
          separationKey,
          separation,
        },
      })
    );

    setOpenSeparationDialog(false);

    // start polling for completion.  Initial delay of 3 minutes.
    await pollTaskCompletion(pendingSeparation, 3 * 60 * 1000);
  };
  
  // Creates a new, pending separation object to store in Redux.  Will be updated when the
  // ECS task completes.
  function CreateReduxSeparationObject(separationKey, sourceKey, taskArn) {
    const keyParts = separationKey.split("/");

    return {
      sourceKey: sourceKey,
      stems: null,
      method: keyParts[keyParts.length - 2],
      model: keyParts[keyParts.length - 1],
      status: SeparationStatus.PENDING,
      taskArn: taskArn,
      zipKey: null,
      size: null,
    };
  }

  const pollTaskCompletion = async (
    pendingSeparation,
    initialDelayInMilliseconds
  ) => {
    // wait for initialDelayInMilliseconds to avoid unnecessary poll requests.
    // May want to tune this per-method/model.
    await delay(initialDelayInMilliseconds);

    while (true) {
      // Check task whether task is still running...
      const isRunning = await IsTaskRunning(pendingSeparation.taskArn);

      if (!isRunning) {

        await UpdatePendingSeparation(file, pendingSeparation, dispatch);

        return;
      }

      // Wait for 30 seconds before polling again
      await delay(30000);
    }
  };

  const getPresignedStemUrls = async (stems) => {
    try {
        const presignedUrls = await Promise.all(
          stems.map(async (stem) => await GetPresignedUrlForFile(stem.key, "private"))
        );
        return presignedUrls.filter(url => url !== null); // Filter out any null values due to errors
      } catch (error) {
        console.error("Error getting presigned URLs for stems:", error);
        return [];
      }
  };

  return (
    <Box className="App-body">
      <Box className="App-listbox" width="100vw">
        <Box sx={{ height: "100%", width: { xs: "94%", sm: "80%" } }}>
          <Box
            sx={{
              height: Object.keys(separationList).length > 0 ? "60%" : "20%",
              flexGrow: 1,
              overflowY: "auto",
            }}
          >
            {Object.keys(separationList).length > 0 && (
              <SeparationList
                separationList={separationList}
                onDelete={handleDelete}
                onItemClick={handleClick}
              />
            )}
          </Box>

          <Box
            sx={{
              borderBottom: 1,
              display: "flex",
              borderColor: "divider",
              justifyContent: "center",
              alignItems: "center",
              height: "40%",
            }}
          >
            <Button
              onClick={() => {
                setOpenSeparationDialog(true);
              }}
              sx={{ color: "white.main", backgroundColor: "primary.main" }}
              autoFocus
              variant="contained"
            >
              <Typography color="white">Create New Separation</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
      <PlayerModal
        open={openPlayerModal}
        onClose={handlePlayerClose}
        file={file}
        stemUrls={stemUrls}
      />
      <SoundSeparationDialog
        open={openSeparationDialog}
        onClose={handleSeparationClose}
        onCancel={handleSeparationCancel}
        file={file}
      />
    </Box>
  );
}

export default FileSeparations;
