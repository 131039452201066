import React from "react";
import { Box, Grid, Typography } from "@mui/material";

function TermsOfUse() {
  return (
    <Box sx={{mb:20}}>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Box padding={5}>
            <br />
            <Typography
              sx={{ marginTop: "10rem" }}
              color="primary"
              variant="h1"
              align="center"
              gutterBottom
            >
              Terms of Use for SoundShatter.com
            </Typography>
            <Typography
              sx={{ marginTop: "5rem" }}
              color="white.main"
              variant="h4"
              paragraph
            >
              Welcome to SoundShatter.com. These Terms of Use govern your access
              to and use of the website. By using SoundShatter.com, you agree to
              comply with and be bound by these terms.
            </Typography>
            <Typography
              sx={{ marginTop: "5rem" }}
              color="primary"
              variant="h5"
              gutterBottom
            >
              1. Acceptance of Terms
            </Typography>
            <Typography color="white" variant="body1" paragraph>
              By using SoundShatter.com, you acknowledge that you have carefully
              read, understood, and agree to be bound by these Terms of Use. If
              you do not agree with any part of these terms, please do not use
              the website. If you are accepting these Terms on behalf of an
              organization or entity, you represent and warrant that you have
              the authority to bind that organization or entity to these Terms.
            </Typography>
            <Typography
              color="secondary"
              variant="body1"
              paragraph
            ></Typography>
            <Typography
              sx={{ marginTop: "1rem" }}
              color="primary"
              variant="h5"
              gutterBottom
            >
              2. User Responsibilities
            </Typography>
            <Typography color="white" variant="body1" paragraph>
              You are responsible for maintaining the confidentiality of your
              account and password and for restricting access to your computer
              or device to prevent unauthorized access to your account. You
              shall not use the website for any illegal or unauthorized purpose
              and must comply with all applicable laws and regulations.
            </Typography>
            <Typography
              sx={{ marginTop: "1rem" }}
              color="primary"
              variant="h5"
              gutterBottom
            >
              3. Intellectual Property
            </Typography>
            <Typography color="white" variant="body1" paragraph>
              SoundShatter.com and its original content, features, and
              functionality are owned by SoundShatter and are protected by
              international copyright, trademark, patent, trade secret, and
              other intellectual property or proprietary rights laws.
            </Typography>
            <Typography
              sx={{ marginTop: "1rem" }}
              color="primary"
              variant="h5"
              gutterBottom
            >
              4. Limitation of Liability
            </Typography>
            <Typography color="white" variant="body1" paragraph>
              SoundShatter and its affiliates, officers, directors, employees,
              agents, partners, and licensors shall not be liable for any
              direct, indirect, incidental, special, consequential, exemplary,
              or punitive damages, including but not limited to damages for loss
              of profits, goodwill, use, data, or other intangible losses
              resulting from your use of SoundShatter.com.
            </Typography>
            <Typography
              sx={{ marginTop: "1rem" }}
              color="primary"
              variant="h5"
              gutterBottom
            >
              5. Indemnification
            </Typography>
            <Typography color="white" variant="body1">
              You agree to defend, indemnify, and hold harmless SoundShatter and
              its affiliates, officers, directors, employees, agents, partners,
              and licensors from any claim or demand, including reasonable
              attorneys' fees, made by any third party due to or arising out of
              your use of SoundShatter.com, your violation of these Terms of
              Use, or your violation of any rights of another.
            </Typography>
            <Typography
              sx={{ marginTop: "1rem" }}
              color="primary"
              variant="h5"
              gutterBottom
            >
              6. User Conduct
            </Typography>
            <Typography color="white" variant="body1">
              You agree to use SoundShatter.com in a manner consistent with all
              applicable laws and regulations. Prohibited activities include,
              but are not limited to, uploading or sharing content that
              infringes upon the intellectual property rights of others,
              contains viruses or malicious code, promotes discrimination,
              harassment, or violence, or violates any privacy rights. Violation
              of these Terms of Use may result in your account being disabled or
              your subscription being canceled at SoundShatter's sole
              discretion. SoundShatter reserves the right to take appropriate
              action, including but not limited to restricting or terminating
              your access to the website and deleting all user content if you
              fail to comply with these terms.
            </Typography>
            <Typography
              sx={{ marginTop: "1rem" }}
              color="primary"
              variant="h5"
              gutterBottom
            >
              7. Free Tier Features and Access
            </Typography>
            <Typography color="white" variant="body1">
              Access to free tier features and services is subject to
              SoundShatter's sole discretion and may be modified or canceled at
              any time without prior notice.
            </Typography>
            <Typography
              sx={{ marginTop: "1rem" }}
              color="primary"
              variant="h5"
              gutterBottom
            >
              7. File Retention Policy
            </Typography>
            <Typography color="white" variant="body1">
              We have implemented a file retention policy to minimize potential
              privacy exposure and maintain operational efficiency. By using our
              service, you agree to the following file retention policy: a. Free
              Tier Accounts: Uploaded files will be automatically deleted from
              our servers if they have not been accessed for 3 months. b. Paid
              Tier Accounts: Uploaded files will be retained indefinitely as
              long as the subscription remains active. However, if the
              subscription ends, the same retention policy applies, and uploaded
              files will be automatically deleted from our servers if they have
              not been accessed for 3 months. Please note that the retention
              period begins from the date of the last file access. Regardless of
              your account type, we strongly recommend downloading and storing
              your files elsewhere to ensure their availability beyond our
              retention policy. By continuing to use our service, you
              acknowledge and agree to the file retention policy stated above.
              It is your responsibility to ensure the appropriate backup and
              storage of your files to avoid any potential loss.
            </Typography>
            <Typography
              sx={{ marginTop: "1rem" }}
              color="primary"
              variant="h5"
              gutterBottom
            >
              8. Modification and Termination
            </Typography>
            <Typography color="white" variant="body1" paragraph>
              SoundShatter reserves the right, at its sole discretion, to modify
              or terminate the website or to modify these Terms of Use at any
              time without prior notice. Continued use of SoundShatter.com after
              any such changes shall constitute your consent to such changes.
            </Typography>
            <Typography
              sx={{ marginTop: "1rem" }}
              color="primary"
              variant="h5"
              gutterBottom
            >
              9. Dispute Resolution
            </Typography>
            <Typography color="white" variant="body1">
              In the event of any dispute arising under or in connection with
              these Terms of Use, both parties agree to first attempt to resolve
              the dispute through good-faith negotiation. If the parties are
              unable to reach a mutually satisfactory resolution through
              negotiation, any unresolved disputes shall be submitted to binding
              arbitration in accordance with the rules of a recognized
              arbitration organization. The arbitration shall take place in
              Seattle WA, and the decision of the arbitrator(s) shall be final
              and binding upon the parties.
            </Typography>
            <Typography
              sx={{ marginTop: "1rem" }}
              color="primary"
              variant="h5"
              gutterBottom
            >
              10. Severability
            </Typography>
            <Typography color="white" variant="body1">
              If any provision of these Terms of Use is found to be invalid,
              illegal, or unenforceable, the remaining provisions shall continue
              in full force and effect. The invalid, illegal, or unenforceable
              provision shall be deemed severed from these Terms, and the
              parties shall make good faith efforts to replace it with a valid,
              legal, and enforceable provision that reflects the original intent
              of the parties.
            </Typography>
            <Typography
              sx={{ marginTop: "1rem" }}
              color="primary"
              variant="h5"
              gutterBottom
            >
              11. Disclaimer of Warranties
            </Typography>
            <Typography color="white" variant="body1">
              SoundShatter.com is provided on an "as is" and "as available"
              basis. SoundShatter makes no warranties, express or implied,
              regarding the accuracy, reliability, availability, or suitability
              of the website or its content for any particular purpose.
              SoundShatter does not warrant that the website will be error-free,
              secure, or uninterrupted, nor does it make any warranty as to the
              results that may be obtained from the use of the website. Use of
              the website is at your own risk.
            </Typography>
            <Typography
              sx={{ marginTop: "1rem" }}
              color="primary"
              variant="h5"
              gutterBottom
            >
              12. Commercial Use
            </Typography>
            <Typography color="white" variant="body1">
              The use of SoundShatter.com for commercial purposes is expressly
              prohibited without prior written agreement from SoundShatter. For
              any inquiries or requests regarding commercial use, please contact
              us for further information.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default TermsOfUse;
