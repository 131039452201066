import { React, useState, useEffect } from "react";
import VolumeDownIcon from "@mui/icons-material/VolumeDown";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import { Box, Slider, Typography } from "@mui/material";
import { WaveformHeight } from "const";

function TrackControl(props) {
  const {
    stem,
    index,
    masterVolume,
    masterMute,
    onVolumeChange,
    onMuteChange,
    isReady,
    reset,
  } = props;
  const [resetKey, setResetKey] = useState(stem.url);

  // note - lift this and similar styling for master slider into a separate component to reduce duplicated code.
  const sliderStyles = {
    "& .MuiSlider-rail, & .MuiSlider-track, & .MuiSlider-thumb": {
      backgroundColor: "primary.main", // Set the color to blue for all elements
    },
  };

  useEffect(() => {
      onVolumeChange(index, masterVolume);
  }, [masterVolume]);

  useEffect(() => {
      onMuteChange(index, masterMute);
  }, [masterMute]);

  useEffect(() => {
    const { url } = stem;

    if (reset) {
      setResetKey({ url });
    }
  }, [reset, stem, masterVolume, masterMute, resetKey]);

  const handleLocalVolumeChange = (index) => (event, value) => {
    if (isReady) {
      onVolumeChange(index, value);
    }
  };

  const handleLocalMuteToggle = () => {
    if (isReady) {
      onMuteChange(index, !stem.isMuted);
    }
  };

  function getRootFileName(url) {
    // Split the URL by the forward slash "/"
    const parts = url.split("/");
    // Get the last part of the URL (the filename with extension)
    const fileNameWithExtension = parts[parts.length - 1];
    // Split the filename by the dot "."
    const fileNameParts = fileNameWithExtension.split(".");
    // Return the first part of the filename (the root filename)
    return fileNameParts[0];
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: `${WaveformHeight}px`,
        justifyContent: "center",
      }}
    >
      {stem && (
        <Box key={`${stem.url}_${resetKey}`} sx={{ position: "relative" }}>
          {/* Container for slider and mute control */}
          <Box
            sx={{
              display: "flex",
              height: WaveformHeight,
              alignItems: "center",
              justifyContent: "center",
              height: "100%", // Take up the full height of the container
            }}
          >
            {/* Slider and mute control */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                justifyContent: "center", // Center the content horizontally
              }}
            >
              <Slider
                value={stem.volume}
                onChange={handleLocalVolumeChange(index)}
                color={stem.isMuted ? "white" : "primary"}
                sx={{ ...sliderStyles, mx: 1, width: {xs: "60px", lg: "100px" } }}
              />
              {/* Volume icon */}
              {stem.isMuted ? (
                <VolumeOffIcon
                  onClick={handleLocalMuteToggle}
                  sx={{ color: "white.main" }}
                />
              ) : stem.volume > 0 ? (
                <VolumeUpIcon
                  onClick={handleLocalMuteToggle}
                  sx={{ color: "primary.main" }}
                />
              ) : (
                <VolumeDownIcon
                  onClick={handleLocalMuteToggle}
                  sx={{ color: "primary.main" }}
                />
              )}
            </Box>
          </Box>
  
          {/* Root file name */}
          <Typography
            sx={{
              position: "absolute",
              top: {xs: 35, sm: 27},// Offset from top the container
              width: "100%", // Take up the full width of the container
              color: "white.main",
              textAlign: "center",
            }}
            display="block"
            variant="caption"
          >
            {getRootFileName(stem.url)}
          </Typography>
        </Box>
      )}
    </Box>
  );
  
  
}
export default TrackControl;
