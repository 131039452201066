// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function DetailedFooter({ content }) {
  const { brand, socials, menus, copyright } = content;

  return (
    <MKBox component="footer" p={2}>
      <Container>
        <Grid container spacing={3}>
        <Grid container spacing={3} sx= {{ flexWrap: {xs: "wrap", lg: "nowrap"}, justifyContent: "space-around"}}>
          <Grid item xs={12} lg={3} mb={{ xs: 6, lg: 0 }} flexDirection={{ xs: "column" }}>
            <MKTypography
              color="primary"
              variant="h4"
              sx={{
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "primary.main",
                textDecoration: "none",
                textAlign: { xs: "center", lg: "left" },
                pr: {lg: 30}
              }}
            >
              {brand.name}
            </MKTypography>
            <MKTypography color="white" variant="body2" mb={3} pb={1} pr={3}>
              {brand.description}
            </MKTypography>
            <MKBox display="flex" alignItems="center">
              {socials.map(({ icon, link }, key) => (
                <MKTypography
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="body2"
                  color="secondary"
                  mr={key === socials.length - 1 ? 0 : 3}
                  sx={{ fontSize: "1.125rem" }}
                >
                  {icon}
                </MKTypography>
              ))}
            </MKBox>
          </Grid>
          <Grid container spacing={3} sx={{ justifyContent: "space-evenly"}}>

          {menus.map(({ name: title, items }, key) => (
            <Grid
              key={title}
              item
              xs={6}
              md={2}
            >
              <MKTypography
                component="h6"
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
                color="primary"
                mb={1}
                ml={key === 0 ? { xs: 0, lg: "auto" } : 0}
                sx={{paddingLeft: {xs: "35%", md: "0"}}}
              >
                {title}
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                {items.map(({ name, route, href }) => (
                  <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25} >
                    {href ? (
                      <MKTypography
                        component="a"
                        href={href}
                        rel="noreferrer"
                        variant="button"
                        color="white"
                        fontWeight="regular"
                        textTransform="capitalize"
                        pb={0.5}
                        sx={{paddingLeft: {xs: "35%", md: "0"}}}
                      >
                        {name}
                      </MKTypography>
                    ) : (
                      <MKTypography
                        component={Link}
                        to={route}
                        variant="button"
                        color="white"
                        fontWeight="regular"
                        textTransform="capitalize"
                        pb={0.5}
                        sx={{paddingLeft: {xs: "35%", md: "0"}}}
                      >
                        {name}
                      </MKTypography>
                    )}
                  </MKBox>
                ))}
              </MKBox>
            </Grid>
          ))}
          </Grid>
          </Grid>
          <Grid item xs={12} pr={3} sx={{ textAlign: "center", mb: 3 }}>
            <Divider />
            {copyright}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Typechecking props for the DetailedFooter
DetailedFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DetailedFooter;
