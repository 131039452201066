/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import MiniReviewCard from "examples/Cards/ReviewCards/MiniReviewCard";

// Images
import review1 from "assets/images/team-3.jpg";
import review2 from "assets/images/marie.jpg";
import review3 from "assets/images/kal-visuals.jpg";

function Testimonials() {
  return (
    <MKBox mt={5} py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          justifyContent="center"
          mx="auto"
          sx={{ textAlign: "center" }}
        >
          <MKTypography variant="h2" color="warning" textGradient>
            What people are saying...
          </MKTypography>
        </Grid>
        <Grid container spacing={3} mt={8}>
          <Grid item xs={12} md={8} lg={4}>
            <MiniReviewCard
              color="transparent"
              review="I'm astonished.  This is so cool!"
              author={{ image: review1, name: "Lucy C.", date: "Posted on 28 April" }}
            />
          </Grid>
          <Grid item xs={12} md={8} lg={4}>
            <MiniReviewCard
              color="warning"
              review="Your comments could go here!"
              author={{ name: "Customer review", date: "TBD" }}
            />
          </Grid>
          <Grid item xs={12} md={8} lg={4}>
            <MiniReviewCard
              review="O.M.G."
              author={{ image: review3, name: "Nancy M.", date: "Posted on 16 May" }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Testimonials;
