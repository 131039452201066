// Stripe.com redirects to this page after a payment is complete.  Provides CHECKOUT_SESSION_ID as query param

import { Box, Button, LinearProgress } from "@mui/material";
import { API, Auth } from "aws-amplify";
import React, { useState, useEffect } from "react";
import { GetCognitoIdentityId } from "utils";
import { useSearchParams } from "react-router-dom";
import MKTypography from "components/MKTypography";
import { useTheme } from "@emotion/react";
import { SignInUser } from "utils";
import CustomAuthenticator from "components/CustomAuthenticator";
import { useDispatch } from "react-redux";

function PaymentConfirmation() {
  const [subscriptionProcessingComplete, setSubscriptionProcessingComplete] = useState(false);
  const [subscriptionResult, setSubscriptionResult] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    // async func to process new subscription.  Should either return new permissions/subscription level, or we
    // need to call SignInUser again to update permissions on the client (if that works - need to test).
    const processNewSubscription = async () => {
      try {
        const session_id = searchParams.get("session_id");
        const postData = { checkoutSessionId: session_id };

        const cognitoIdentityId = await GetCognitoIdentityId();

        const subscriptionResult = await API.post(
          "soundshatterapi",
          `/users/${cognitoIdentityId}/account/subscription`,
          {
            body: postData,
          }
        );

        // Sign in the user again to pick up updated FeatureList.
        const currentUser = await Auth.currentAuthenticatedUser();
        await SignInUser(dispatch, currentUser.username);

        setSubscriptionResult(subscriptionResult.body);
      } catch (error) {
        console.log(error);
      } finally {
        setSubscriptionProcessingComplete(true); // one way or the other, we're done..
      }
    };

    processNewSubscription();
  }, [searchParams, dispatch]);

  return (
    <CustomAuthenticator>
    <Box
      className="App-body"
      sx={{ textAlign: "center", mt: 10, width: { xs: "90%", sm: "60%" } }}
    >
      {subscriptionProcessingComplete && (
        <React.Fragment>
          <MKTypography variant="h5" sx={{ mt: 20, color: "white.main" }}>
            Subscription Processing complete!
          </MKTypography>
          <MKTypography variant="h3" sx={{ mt: 10, color: "white.main" }}>
            {subscriptionResult.plan}
          </MKTypography>
          <Button
            href="Home"
            style={theme.SoundShatterButton}
            variant="contained"
            sx={{ color: "white.main", mt: 5 }}
          >
            Thank you!
          </Button>
        </React.Fragment>
      )}
      {!subscriptionProcessingComplete && (
        <Box>
          <LinearProgress sx={{ mt: 20 }} />
          <MKTypography variant="h5" sx={{ color: "white.main", mt: 20 }}>
            Processing your subscription
          </MKTypography>
        </Box>
      )}
    </Box>
    </CustomAuthenticator>
  );
}

export default PaymentConfirmation;
