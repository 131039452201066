import React from "react";
import { Box, Grid, Typography } from "@mui/material";

function Privacy() {
  return (
    <Box className="App-body">
      <Grid container justifyContent="center">
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Box>
            <br />
            <Typography
              sx={{ marginTop: "1rem" }}
              color="primary"
              variant="h1"
              align="center"
              gutterBottom
            >
              SoundShatter.com Privacy Policy Statement
            </Typography>
            <Typography sx={{ mt: "2rem" }} variant="body1" paragraph>
              At SoundShatter.com, we value the privacy and security of our
              users. This Privacy Policy outlines how we collect, use, and
              protect your personal information when you access and use the
              website. By using SoundShatter.com, you consent to the practices
              described in this policy.
            </Typography>
            <Typography
              sx={{ mt: "3rem" }}
              color="primary"
              variant="h5"
              gutterBottom
            >
              1. Collection of Information
            </Typography>
            <Typography variant="body1" paragraph>
              We collect certain information from you when you visit our website
              or use our services. Categories of information include:
            </Typography>
            <Typography ariant="body1">
              a. Personal Information: We want to assure you that we do not and
              will never sell your personal information to another entity. We
              only collect personally identifiable information (such as your
              name, address, or email) if you voluntarily provide it to us. This
              information is used to improve our website and services, ensuring
              the best user experience, and to contact you if necessary for
              important notifications or updates.
            </Typography>
            <Typography variant="body1">
              b. Usage Information: We may collect non-personal information
              about your interactions with our website, such as your IP address,
              browser type, device information, and the pages you visit. This
              information is collected to improve the quality and performance of
              our website and services.
            </Typography>
            <Typography variant="body1">
              c. Uploaded Files: When you use our sound separation services, you
              may upload files to our platform. We do not typically inspect the
              contents of these files. However, in the event that there is
              reason to be concerned about uploaded content, we reserve the
              right to inspect and/or remove any such file to ensure compliance
              with our terms of service and to protect the security, integrity,
              and lawful operation of the platform.
            </Typography>
            <Typography
              sx={{ marginTop: "1rem" }}
              color="primary"
              variant="h5"
              gutterBottom
            >
              2. Use of Information
            </Typography>
            <Typography variant="body1" paragraph>
              We use the information collected from you for the following
              purposes:
            </Typography>
            <Typography variant="body1">
              a. To provide and improve our services: We use the information to
              process your sound separation requests, deliver the requested
              services, and enhance the functionality and user experience of
              SoundShatter.com.
            </Typography>
            <Typography variant="body1">
              b. To communicate with you: We may use your email address to send
              you important notifications regarding your account, service
              updates, and changes to our policies.
            </Typography>
            <Typography
              sx={{ marginTop: "1rem" }}
              color="primary"
              variant="h5"
              gutterBottom
            >
              3. Protection of Information
            </Typography>
            <Typography variant="body1" paragraph>
              We take appropriate measures to safeguard your personal
              information and maintain its confidentiality. We implement
              industry-standard security practices to protect against
              unauthorized access, alteration, disclosure, or destruction of
              your information.
            </Typography>
            <Typography
              sx={{ marginTop: "1rem" }}
              color="primary"
              variant="h5"
              gutterBottom
            >
              4. Sharing of Information
            </Typography>
            <Typography variant="body1" paragraph>
              We do not sell, trade, or rent your personal information to third
              parties for marketing purposes. However, we may share your
              information in the following limited circumstances:
            </Typography>
            <Typography variant="body1">
              a. Service Providers: We may engage trusted third-party service
              providers to assist us in delivering our services. These providers
              have access to your information solely for the purpose of
              performing tasks on our behalf and are obligated to maintain its
              confidentiality.
            </Typography>
            <Typography variant="body1">
              b. Legal Requirements: We may disclose your information if
              required to do so by law or in response to valid requests from
              government authorities.
            </Typography>
            <Typography
              sx={{ marginTop: "1rem" }}
              color="primary"
              variant="h5"
              gutterBottom
            >
              5. File Retention Policy
            </Typography>
            <Typography variant="body1" paragraph>
              We implement a file retention policy to minimize potential privacy
              exposure and to keep operational costs under better control. For
              free tier accounts, user content will be automatically deleted
              from our servers if it has not been accessed for 3 months. For
              users with a paid tier account, user content will be retained
              indefinitely as long as the subscription remains active. However,
              if the subscription ends, the same retention policy applies, and
              user content will be automatically deleted from our servers if it
              has not been accessed for 3 months. Please note that the retention
              period begins from the date of the last file access. Regardless of
              your account type, we strongly recommend downloading and storing
              your files elsewhere to ensure their availability beyond our
              retention policy.
            </Typography>
            <Typography
              sx={{ marginTop: "1rem" }}
              color="primary"
              variant="h5"
              gutterBottom
            >
              6. Use of Cookies
            </Typography>
            <Typography variant="body1" paragraph>
              We do not set any cookies on client systems. However, please note
              that third-party services may use cookies to collect and store
              information. These cookies are governed by the privacy policies of
              the respective third-party services and are not under our control.
            </Typography>
            <Typography
              sx={{ marginTop: "1rem" }}
              color="primary"
              variant="h5"
              gutterBottom
            >
              7. Changes to the Privacy Policy
            </Typography>
            <Typography variant="body1" paragraph>
              We may update this Privacy Policy from time to time to reflect
              changes in our practices or legal obligations. We encourage you to
              review this policy periodically to stay informed about how we
              collect, use, and protect your information.
            </Typography>
            <Typography
              sx={{ marginTop: "1rem" }}
              color="primary"
              variant="h5"
              gutterBottom
            >
              8. Contact Us
            </Typography>
            <Typography variant="body1">
              If you have any questions or concerns about this Privacy Policy or
              our data practices, please contact us at [insert contact
              information].
            </Typography>
            <Typography variant="body1" paragraph>
              By using SoundShatter.com, you signify your acceptance of this
              Privacy Policy.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </Box>
  );
}

export default Privacy;
