import { Box } from "@mui/material";

function Error404() {
  return (
      <Box className="App-header">
          <h1>Error 404</h1>
      </Box>
  );
}

export default Error404;
