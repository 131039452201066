import { Box, Button, Dialog, Slide, Typography } from "@mui/material";
import MultitrackPlayer from "../MultitrackPlayer";
import React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PlayerModal = ({ open, onClose, file, stemUrls }) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      height="100%"
    >
      <Box className="App-body-player" sx={{ height: "50%", flexGrow: 1 }}>
        <Box sx={{mt: {xs: 5, lg:10}, width: {xs: "80%", sm: "70%"}}}>
          <Typography
            sx={{
              justifyContent: "center",
              display: "flex",
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "primary.main",
              textDecoration: "none",
              fontSize: "2rem",
              mb: 5
            }}
          >
            SoundShatter
          </Typography>
          <Box
          sx={{ border: "1px solid #fb8c00", borderRadius: "10px", p: 4 }}
        >          {stemUrls.length !== 0 ? (
            <MultitrackPlayer metaData={file.metadata} stemUrls={stemUrls} />
          ) : null}
          </Box>
        </Box>
        <Button
            sx={{ backgroundColor: "primary.main", color: "white.main", mt: 5 }}
            onClick={onClose}
            variant="contained"
          >
            Close
          </Button>
      </Box>
    </Dialog>
  );
};

export default PlayerModal;
