import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import { API, Auth } from "aws-amplify";
import validator from "validator";
import InvalidEmailDialog from "components/Dialogs/InvalidEmailDialog";
import "../FileList.css"; // import CSS file for styling
import { GetCognitoIdentityId } from "utils";

function Account() {
  const [userEmail, setUserEmail] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [subscription, setSubscription] = useState("");
  const [showProgress, setShowProgress] = useState(false);
  
  const StripePortalLink = "https://billing.stripe.com/p/login/test_eVa29l0aa6PHdc4bII";
  
  useEffect(() => {
    setSubscription(" Soundshatter Premium (billed annually)");

    const fetchAccountInfo = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setUserEmail(user.attributes.email);
      } catch (error) {
        console.log(`Unable to get email for current user.  Error: ${error}`);
      }
    };

    fetchAccountInfo();
  }, []);

  const handleInvalidEmailClose = async () => {
    setInvalidEmail(false);
  };

  const handleContactEmailUpdate = async (e) => {
    try {
      setShowProgress(true);
      if (validator.isEmail(newUserEmail)) {
        await API.put(
          "soundshatterapi",
          `/users/${await GetCognitoIdentityId()}/account/emails/${"contact"}`,
          {
            body: { contactEmail: newUserEmail },
          }
        );
        setUserEmail(newUserEmail);
      } else {
        setInvalidEmail(true);
      }
    } catch (error) {
      console.log(`error updating contact email: ${error}`);
    }
    finally {
      setShowProgress(false);

    }
  };

  return (
    <React.Fragment>
      <Grid
        container
        direction="column"
        justifyContent="space-around"
        alignItems="center"
        className="file-list-container"
        style={{ height: "100vh" }}
      >
        <Grid item>
          <Typography sx={{ color: "white.main", mt: 15 }} variant="h2">
            Account management
          </Typography>
        </Grid>
        {/* First Inner Grid */}
        <Grid item>
          <Box sx={{ textAlign: "center" }}>
            <Typography sx={{ color: "warning.main" }} variant="body1">
              Primary contact email
            </Typography>
            <Typography sx={{ color: "white.main" }} variant="body1">
              {userEmail}
            </Typography>
            <Box sx={{ mt: 2, width: "320px", flexDirection: "column" }}>
              <TextField
                label="New Email"
                variant="outlined"
                value={newUserEmail}
                onChange={(e) => setNewUserEmail(e.target.value)}
                sx={{ mt: 2, width: "100%" }}
              />{" "}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {showProgress && (
                  <CircularProgress
                    sx={{ mt: 4, width: "100%" }}
                    color="primary"
                  />
                )}
              </Box>
              <Button
                variant="contained"
                onClick={handleContactEmailUpdate}
                sx={{
                  backgroundColor: "primary.main",
                  color: "white.main",
                  mt: 4,
                  width: "100%",
                }}
              >
                Update
              </Button>
            </Box>
          </Box>
        </Grid>

        {/* Second Inner Grid */}
        <Grid item>
          <Box sx={{ textAlign: "center" }}>
            <Typography sx={{ color: "warning.main" }} variant="body1">
              Subscription
            </Typography>
            <Typography sx={{ color: "white.main" }} variant="body1">
              {subscription}
            </Typography>
            <Button
              variant="contained"
              onClick={() => {window.location.replace(`${StripePortalLink}`)}}
              sx={{
                backgroundColor: "primary.main",
                color: "white.main",
                mt: 4,
                width: "320px",
              }}
            >
              Manage
            </Button>
          </Box>
        </Grid>
        <Grid item></Grid>
      </Grid>
      <InvalidEmailDialog
        open={invalidEmail}
        onClose={handleInvalidEmailClose}
      />
    </React.Fragment>
  );
}

export default Account;
