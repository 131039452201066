import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
  Slide,
} from "@mui/material";
import Link from '@mui/material/Link'

const paperStyle = {
  style: {
    backgroundColor: "#333333",
    borderRadius: "8px",
  },
};

const QuotaExceededDialog = ({ open, onClose }) => {
  
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Slide}
      transitionDuration={150}
      TransitionProps={{ direction: "down" }}
      PaperProps={paperStyle}
    >
      <DialogContent>
        <Box sx={{textAlign: "center", mt: 2}}>
          <Typography sx={{ color: "warning.main", mt: 2 }} variant="body1">
            Your library is full
          </Typography>
          <Typography sx={{ color: "white.main", mt: 4 }} variant="body1">
            Please <Link color="warning.main" underline="always" href="/account">upgrade your subscription</Link> or create space in your library by deleting files.
          </Typography>
        </Box>
      </DialogContent>
      <Box sx={{ mt: 5 }}>
        <Box sx={{ display: "flex", justifyContent: "space-evenly", mb: 5 }}>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{ backgroundColor: "primary.main", color: "white.main" }}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default QuotaExceededDialog;
