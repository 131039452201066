// CustomAuthenticator.js
import {
  Authenticator,
  CheckboxField,
  ThemeProvider,
  useTheme,
} from "@aws-amplify/ui-react";
import React from "react";

const CustomAuthenticator = ({ children }) => {
  const { tokens } = useTheme();

  // currently not using this theme.  TO USE:  add theme={theme} to the Authenticator below.
  const theme = {
    name: "Auth Example Theme",
    tokens: {
      colors: {
        background: {
          primary: {
            value: tokens.colors.blue["100"].value,
          },
          secondary: {
            value: tokens.colors.blue["100"].value,
          },
        },
        font: {
          interactive: {
            value: tokens.colors.white.value,
          },
        },
        brand: {
          primary: {
            10: tokens.colors.teal["100"],
            80: tokens.colors.teal["40"],
            90: tokens.colors.teal["20"],
            100: tokens.colors.teal["10"],
          },
        },
      },
      components: {
        tabs: {
          item: {
            _focus: {
              color: {
                value: tokens.colors.white.value,
              },
            },
            _hover: {
              color: {
                value: tokens.colors.yellow["80"].value,
              },
            },
            _active: {
              color: {
                value: tokens.colors.white.value,
              },
            },
          },
        },
      },
    },
  };

  return (
    <>
      <ThemeProvider theme={null}>
        <Authenticator
          variation="modal"
          initialState="signUp"
          components={{
            SignUp: {
              FormFields() {
                return (
                  <>
                    {/* Re-use default `Authenticator.SignUp.FormFields` */}
                    <Authenticator.SignUp.FormFields />

                    {/* Append & require Terms & Conditions field to sign up  */}
                    <CheckboxField
                      errorMessage="You must agree to the Terms & Conditions"
                      hasError={false}
                      name="acknowledgement"
                      value="yes"
                      label={
                        <span>
                          I agree to the{" "}
                          <a href="/TermsOfUse" target="_blank">
                            Terms of Use
                          </a>
                        </span>
                      }
                    />
                  </>
                );
              },
            },
          }}
          services={{
            async validateCustomSignUp(formData) {
              if (!formData.acknowledgement) {
                return {
                  acknowledgement: "You must agree to the Terms & Conditions",
                };
              }
            },
          }}
        >
          {children}
        </Authenticator>
      </ThemeProvider>
    </>
  );
};

export default CustomAuthenticator;
