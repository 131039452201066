import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    signIn(state, action) {
      state[action.payload.key] = action.payload.user;
    },
  },
});

export const {
  signIn,
} = userSlice.actions;

export default userSlice.reducer;
