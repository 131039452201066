/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DetailedFooter from "../DetailedFooter";

function Footer() {
  const date = new Date().getFullYear();

  const footerContent = {
    brand: {
      name: "SoundShatter",
      description: ""
    },
    socials: [
      {
        icon: <i className="fab fa-facebook" />,
        link: "https://www.facebook.com",
      },
      {
        icon: <i className="fab fa-twitter" />,
        link: "https://twitter.com",
      },
      {
        icon: <i className="fab fa-instagram" />,
        link: "https://www.instagram.com",
      },
      {
        icon: <i className="fab fa-pinterest" />,
        link: "https://ro.pinterest.com",
      },
      {
        icon: <i className="fab fa-github" />,
        link: "https://github.com",
      },
    ],
    menus: [
      {
        name: "company",
        items: [
          { name: "about us", href: "/home" },
          { name: "team", href: "/home" },
          { name: "press", href: "/home" },
          { name: "blog", href: "/home" },
        ],
      },
      {
        name: "Support",
        items: [
          { name: "support", href: "/home" },
          { name: "contact us", href: "/home" },
          { name: "FAQ", href: "/home" },
        ],
      },
      {
        name: "legal",
        items: [
          { name: "terms of use", href: "/TermsOfUse" },
          { name: "privacy", href: "/Privacy" },
          { name: "attributions", href: "/home" },
        ],
      },
      {
        name: "resources",
        items: [
          { name: "services", href: "/home" },
          { name: "Merchandise", href: "/home" },
          { name: "pricing", href: "/home#pricing" },
        ],
      },
    ],
    copyright: (
      <MKTypography variant="button" color="primary">
        Copyright &copy; {date} Design by{" "}
        <MKTypography
          component="a"
          href="/home"
          rel="noreferrer"
          variant="button"
          color="primary"
        >
          SoundShatter
        </MKTypography>
        .
      </MKTypography>
    ),
  };

  return <DetailedFooter content={footerContent} />;
}

export default Footer;
