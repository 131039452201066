/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import SimplePricingCard from "examples/Cards/PricingCards/SimplePricingCard";

function Pricing() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabType = (event, newValue) => {
    console.log(activeTab);
    setActiveTab(newValue);
  };

  const basicSpecifications = [
    "5 sound separations per month",
    "1 GB cloud storage",
    "1 GB bandwidth per month",
    "Spleeter separation only",
    "File output format limited to .mp3",
    "Limited support"
  ];

  const standardSpecifications = [
    "20 sound separations per month",
    "10 GB cloud storage",
    "20 GB bandwidth per month",
    "Choose from several high-quality separation methods and models",
    "File output format limited to .mp3",
    "Standard support",
    "2 free months with annual subscription",
  ];

  const premiumSpecifications = [
    "50 sound separations per month",
    "30 GB cloud storage",
    "50 GB bandwidth per month",
    "Choose from all supported separation methods and models",
    "All supported file output types (including lossless formats)",
    "Premium support",
    "2 free months with annual subscription",
  ];

  const commercialSpecifications = [
    "Use for commercial purposes",
    "Full website access",
    "Integrate with our APIs",
    "Negotiate usage levels that are right for you",
    "File-retention policy exceptions",
    "Premium support"
  ];

  return (
    <MKBox
      sx={{ backgroundColor: "black.main" }}
      component="section"
      py={{ xs: 0, md: 12 }}
    >
      <Container>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          md={8}
          mx="auto"
          textAlign="center"
        >
          <MKTypography variant="h1"  sx={{fontWeight: '1000'}} color="warning">
            Pick the right plan for you!
          </MKTypography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={10}
          lg={8}
          justifyContent="center"
          textAlign="center"
          mx="auto"
          mt={6}
        >
          <AppBar position="static">
            <Tabs
              value={activeTab}
              sx={{ backgroundColor: "primary.main" }}
              onChange={handleTabType}
              TabIndicatorProps={{
                style: {
                  backgroundColor: 'orange',
                },
            }}
            >
              <Tab
                id="monthly"
                sx={{ 
                    '&.Mui-selected': { backgroundColor: 'orange', color: 'primary.main'},
                    '&.Mui-selected:focus': { backgroundColor: 'orange', color: 'primary.main'} 
                }}
                label={
                  <MKBox py={0.5} px={2} color="inherit">
                    Monthly
                  </MKBox>
                }
              />
              <Tab
                id="annual"
                sx={{ 
                    '&.Mui-selected': { backgroundColor: 'orange', color: 'primary.main'},
                    '&.Mui-selected:focus': { backgroundColor: 'orange', color: 'primary.main'} 
                }}
                label={
                  <MKBox py={0.5} px={2} color="inherit">
                    Annual
                  </MKBox>
                }
              />
            </Tabs>
          </AppBar>
        </Grid>
        <Grid container spacing={3} mt={6}>
          <Grid item xs={12} sm={6} lg={3}>
            <SimplePricingCard
              variant="contained"
              color="warning"
              title="Basic"
              description="Limited access for new and infrequent visitors"
              price={
                activeTab === 0
                  ? { value: "$0", type: "month" }
                  : { value: "$0", type: "year" }
              }
              action={{ type: "internal", route: "/library", label: "create account" }}
              specifications={basicSpecifications}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <SimplePricingCard
              variant="contained"
              color="warning"
              title="Standard"
              description="Expanded access.  Recommended for casual users.  "
              price={
                activeTab === 0
                  ? { value: "$5", type: "month" }
                  : { value: "$50", type: "year" }
              }
              action={{ type: "internal", route: `/signup/standard/${activeTab === 0
                ? "monthly"
                : "annual" }`,
              label: "sign up now" }}
              specifications={standardSpecifications}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <SimplePricingCard
              variant="contained"
              color="warning"
              title="Premium"
              description="Elevate your SoundShatter experience with our exclusive premium offering"
              price={
                activeTab === 0
                  ? { value: "$10", type: "month" }
                  : { value: "$100", type: "year" }
              }
              action={{ type: "internal", route: `/signup/premium/${activeTab === 0
                ? "monthly"
                : "annual" }`,
              label: "sign up now" }}
              specifications={premiumSpecifications}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <SimplePricingCard
              variant="contained"
              color="dark"
              title="Commercial"
              description="SoundShatter ToU disallows commercial use without prior written agreement."
              price={
                activeTab === 0
                  ? { value: "tbd", type: "month" }
                  : { value: "tbd", type: "year" }
              }
              action={{ type: "internal", route: "/", label: "contact us" }}
              specifications={commercialSpecifications}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Pricing;
