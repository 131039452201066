import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Slide,
} from "@mui/material";
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const paperStyle = {
  style: {
    backgroundColor: "#333333",
    borderRadius: "8px",
  },
};

const ConfirmDeleteSeparationDialog = ({
  open,
  onClose,
  onConfirm,
  separation,
}) => {

  const sourceFile = useSelector((state) =>
    separation ? state.audioFiles[separation.sourceKey] : null
  );

  if (!sourceFile || !separation) {
    return null;
  }

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      TransitionComponent={Slide}
      transitionDuration={150}
      TransitionProps={{ direction: "down" }}
      PaperProps={paperStyle}
    >
      <DialogTitle sx={{ fontSize: "3rem", color: "warning.main" }}>Confirm Deletion</DialogTitle>
      <DialogContent>
        <Box>
          <Typography sx={{ color: "white.main", mt:3 }}>
            Delete the following sound separation?
          </Typography>
          <Typography sx={{ color: "white.main", mt:5 }} variant="h6">
            Artist: { decodeURIComponent(sourceFile.metadata.artist) }
          </Typography>
          <Typography sx={{ color: "white.main" }} variant="h6">
            Album: { decodeURIComponent(sourceFile.metadata.album) }
          </Typography>
          <Typography sx={{ color: "white.main" }} variant="h6">
            Title: { decodeURIComponent(sourceFile.metadata.title) }
          </Typography>
          <Typography variant="h6" sx={ { color: "white.main" } }>
            Method: { decodeURIComponent(separation.method) }
          </Typography>
          <Typography variant="h6" sx={ { color: "white.main" } }>
            Model: { decodeURIComponent(separation.model) }
          </Typography>
        </Box>
      </DialogContent>
      <Box sx={{ mt: 5 }}>
        <Box sx={{ display: "flex", justifyContent: "space-evenly", mb: 5 }}>
          <Button
            onClick={handleCancel}
            sx={{ backgroundColor: "primary.main", color: "white.main" }}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            variant="contained"
            sx={{ backgroundColor: "warning.main", color: "white.main" }}
          >
            Delete
          </Button>
        </Box>
        </Box>
    </Dialog>
  );
};

ConfirmDeleteSeparationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  separation: PropTypes.object,
};

export default ConfirmDeleteSeparationDialog;
