import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Slide,
} from "@mui/material";

const paperStyle = {
  style: {
    backgroundColor: "#333333",
    borderRadius: "8px",
  },
};

const ConfirmDeleteTrackDialog = ({
  open,
  onClose,
  onConfirm,
  file,
  separations,
}) => {
  //nothing to do if there is no file to delete
  if (!file) {
    return;
  }

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      TransitionComponent={Slide}
      transitionDuration={150}
      TransitionProps={{ direction: "down" }}
      PaperProps={paperStyle}
    >
      <DialogTitle sx={{ fontSize: "3rem", color: "warning.main" }}>Confirm Deletion</DialogTitle>
      <DialogContent>
        <Box>
          <Typography sx={{ color: "white.main", mt: 2 }} variant="h5">
            Are you sure you want to delete:{" "}
          </Typography>
          <Typography sx={{ color: "white.main", mt: 4 }} variant="h6">
            Artist: { decodeURIComponent(file.metadata.artist) } 
          </Typography>
          <Typography sx={{ color: "white.main" }} variant="h6">
            Album: { decodeURIComponent(file.metadata.album) } 
          </Typography>
          <Typography sx={{ color: "white.main" }} variant="h6">
            Title: { decodeURIComponent(file.metadata.title) } 
          </Typography>
          {separations.length > 0 && (
            <Typography
              sx={{ color: "white.main", mt: 4 }}
              variant="h5"
            >
              PLEASE NOTE: This track has existing separations that will also be
              deleted.
            </Typography>
          )}
        </Box>
      </DialogContent>
      <Box sx={{ mt: 5 }}>
        <Box sx={{ display: "flex", justifyContent: "space-evenly", mb: 5 }}>
          <Button
            sx={{ backgroundColor: "primary.main", color: "white.main" }}
            onClick={handleCancel}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            sx={{ backgroundColor: "warning.main", color: "white.main" }}
            onClick={handleConfirm}
            variant="contained"
          >
            Delete
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmDeleteTrackDialog;
