import React from "react";
import { Box, Slider, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import VolumeDownIcon from "@mui/icons-material/VolumeDown";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import {
  PlayArrow,
  Pause,
  Replay,
  FastRewind,
  FastForward,
} from "@mui/icons-material";

function MultitrackMasterControls(props) {
  const {
    masterVolume,
    audioPosition,
    isPlaying,
    isMuted,
    onMasterVolumeChange,
    onMasterSeekPositionChange,
    onMasterMuteToggle,
    onPlaybackIconClick,
    isReady,
    reset,
  } = props;

  // Conditionally define the event handlers to disable them if !isReady.
  // We want to render the controls immediately, but they don't become "live" until all stems are loaded and ready to go.
  const handleMasterVolumeChange = isReady ? onMasterVolumeChange : () => {};
  const handleMasterMuteToggle = isReady ? onMasterMuteToggle : () => {};
  const handlePlaybackIconClick = isReady ? onPlaybackIconClick : () => {};
  const handleMasterSeekPositionChange = (event, newValue) => {
    if (isReady) {
      onMasterSeekPositionChange(newValue / 100);
    }
  };

  return (
    <Grid display="flex" flexDirection="column" container wrap="nowrap">
      <Box
        display="flex"
        alignItems="center"
        flexDirection={"column"}
        width="100%"
      >
        <Slider
          value={reset ? 0 : audioPosition * 100}
          onChange={handleMasterSeekPositionChange}
          onChangeCommitted={handleMasterSeekPositionChange}
          color="primary"
          sx={{
            "& .MuiSlider-rail, & .MuiSlider-track, & .MuiSlider-thumb": {
              backgroundColor: "primary.main",
            },
          }}
        />
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item>
          <IconButton
            color="primary"
            onClick={() => handlePlaybackIconClick("rewind")}
            sx={{ padding: 0 }}
          >
            <FastRewind />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => handlePlaybackIconClick("forward")}
            sx={{ padding: 0 }}
          >
            <FastForward />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => handlePlaybackIconClick("reset")}
            sx={{ padding: 0 }}
          >
            <Replay />
          </IconButton>
        </Grid>
        <Grid item>
          {isPlaying ? (
            <IconButton
              color="primary"
              onClick={() => handlePlaybackIconClick("pause")}
            >
              <Pause sx={{ padding: 0, width: "48px", height: "48px" }} />
            </IconButton>
          ) : (
            <IconButton
              color="primary"
              onClick={() => handlePlaybackIconClick("play")}
            >
              <PlayArrow sx={{ padding: 0, width: "48px", height: "48px" }} />
            </IconButton>
          )}
        </Grid>
        {/* Move the Slider and Mute IconButton to the right */}
        <Grid item>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Slider
              value={masterVolume}
              onChange={handleMasterVolumeChange}
              color={isMuted ? "white" : "primary"}
              sx={{
                "& .MuiSlider-rail, & .MuiSlider-track, & .MuiSlider-thumb": {
                  backgroundColor: "primary.main",
                },
                width: { xs: "60px", lg: "100px" },
              }}
            />
            {/* Volume icon */}
            {isMuted ? (
              <VolumeOffIcon
                onClick={handleMasterMuteToggle}
                sx={{ color: "white.main" }}
              />
            ) : masterVolume > 0 ? (
              <VolumeUpIcon
                onClick={handleMasterMuteToggle}
                sx={{ color: "primary.main" }}
              />
            ) : (
              <VolumeDownIcon
                onClick={handleMasterMuteToggle}
                sx={{ color: "primary.main" }}
              />
            )}
          </Box>
        </Grid>{" "}
      </Grid>
    </Grid>
  );
}

export default MultitrackMasterControls;
