import { combineReducers } from "@reduxjs/toolkit";
import audioFilesReducer from "./AudioFilesSlice";
import userReducer from "./UserSlice";
import { LOGOUT } from './actions';

// Create an app reducer
const appReducer = combineReducers({
    audioFiles: audioFilesReducer,
    user: userReducer,
  });
  
// Wrap the app reducer to handle the reset action
const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    // Return the initial state
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
