import { Box, Grid, Typography, Link, Paper } from "@mui/material";
import React from "react";

// Data for music attributions
const musicAcknowledgements = [
    {
      name: "Lovers Like Neon",
      artist: "The Copycuts",
      link: "https://freemusicarchive.org/music/The_Copycuts/Sketches_1599/14_Lovers_Like_Neon",
      license: "https://creativecommons.org/licenses/by-sa/4.0/",
    },
    {
      name: "Fruit Salad",
      artist: "Simon Panrucker",
      link: "https://freemusicarchive.org/music/Simon_Panrucker/Hello_My_Name_Is_Simon_Panrucker/03_Fruit_Salad",
      license: "https://creativecommons.org/licenses/by-sa/4.0/",
    },
    {
      name: "We All Fall Down",
      artist: "Lorenzo's Music",
      link: "https://freemusicarchive.org/music/Lorenzos_Music/Just_Had_To_Let_You_Know/Lorenzos_Music_-_Just_Had_To_Let_You_Know_-_03_We_All_Fall_Down/",
      license: "https://creativecommons.org/licenses/by-sa/3.0/",
    },
    {
      name: "Caroline",
      artist: "TenPenny Joke",
      link: "https://freemusicarchive.org/music/FrostClick/FrostWire_Creative_Commons_Mixtape_2_Side_B/TenPenny_Joke__Caroline__FrostClick_FrostWire_Mixtape_Vol_2",
      license: "https://creativecommons.org/licenses/by-sa/3.0/",
    },
    {
      name: "Daydreaming of The South",
      artist: "Bryan Mathys",
      link: "https://freemusicarchive.org/music/Bryan_Mathys/Wild_Heart/Bryan_Mathys_-_Wild_Heart_-_01_Daydreaming_of_The_South/",
      license: "https://creativecommons.org/licenses/by/4.0/",
    },
  ];
  
  // Data for open source attributions
  const openSourceAttributions = [
    {
      name: "emotion.js",
      repo: "https://github.com/emotion-js/emotion",
      license: "https://github.com/emotion-js/emotion/blob/main/LICENSE",
    },
    {
      name: "material-ui",
      repo: "https://github.com/mui/material-ui",
      license: "https://github.com/mui/material-ui/blob/master/LICENSE",
    },
    {
      name: "bluebird",
      repo: "https://github.com/petkaantonov/bluebird/",
      license: "https://github.com/petkaantonov/bluebird/blob/master/LICENSE",
    },
    {
      name: "jsmediatags-web",
      repo: "https://github.com/zzarcon/jsmediatags-web",
      license:
        "https://github.com/zzarcon/jsmediatags-web/blob/master/LICENSE.md",
    },
    {
      name: "react",
      repo: "https://github.com/facebook/react",
      license: "https://github.com/facebook/react/blob/main/LICENSE",
    },
    {
      name: "react-router-dom",
      repo: "https://github.com/remix-run/react-router",
      license: "https://github.com/remix-run/react-router/blob/main/LICENSE.md",
    },
    {
      name: "validator",
      repo: "https://github.com/validatorjs/validator.js/",
      license: "https://github.com/validatorjs/validator.js/blob/master/LICENSE",
    },
    {
      name: "wavesurfer.js",
      repo: "https://github.com/katspaugh/wavesurfer.js",
      license: "https://github.com/katspaugh/wavesurfer.js/blob/main/LICENSE",
    },
    {
      name: "web-vitals",
      repo: "https://github.com/GoogleChrome/web-vitals",
      license: "https://github.com/GoogleChrome/web-vitals/blob/main/LICENSE",
    },
    {
      name: "boto3",
      repo: "https://github.com/boto/boto3",
      license: "https://github.com/boto/boto3/blob/develop/LICENSE",
    },
    {
      name: "ffmpeg",
      repo: "https://github.com/FFmpeg/FFmpeg",
      license: "https://github.com/FFmpeg/FFmpeg/blob/master/LICENSE.md",
    },
    {
      name: "spleeter",
      repo: "https://github.com/deezer/spleeter",
      license: "https://github.com/deezer/spleeter/blob/master/LICENSE",
    },
    {
      name: "demucs",
      repo: "https://github.com/facebookresearch/demucs",
      license: "https://github.com/facebookresearch/demucs/blob/main/LICENSE",
    },
    {
      name: "numpy",
      repo: "https://github.com/numpy/numpy",
      license: "https://github.com/numpy/numpy/blob/main/LICENSE.txt",
    },
    {
      name: "python-soundfile",
      repo: "https://github.com/bastibe/python-soundfile",
      license: "https://github.com/bastibe/python-soundfile/blob/master/LICENSE",
    },
    {
      name: "torch",
      repo: "https://github.com/pytorch/pytorch",
      license: "https://github.com/pytorch/pytorch/blob/main/LICENSE",
    },
    {
      name: "torchaudio",
      repo: "https://github.com/pytorch/audio",
      license: "https://github.com/pytorch/audio/blob/main/LICENSE",
    },
  ];
  
  function Attributions() {
  const linkStyles = {
    color: "warning.main", // Change the color to the one you prefer
    textDecoration: "underline", // Add an underline
    "&:hover": {
      color: "primary.main", // Change the color on hover
    },
  };

  const gridContainerStyles = {
    border: "1px solid #ccc", // Add a thin border
    borderCollapse: "separate", // Remove internal grid lines
    borderRadius: "4px", // Optional: Add border-radius for a more rounded appearance
    borderColor: "primary.main", // Specify the border color
  };
  const centeredTextStyles = {
    textAlign: "center",
  };
  return (
    <Box sx={{width:{xs: "90vw", lg:"60vw"}}} my={20}>
      {/* Music Acknowledgements Table */}
      <Typography variant="h2" textAlign={"center"} sx={{ color: "white.main", mb: 3 }}>
       Attributions
      </Typography>
      <Box>
        <Grid container justifyContent="center" sx={{ mb: 2, mt: 10 }}>
          <Typography variant="h4" sx={{ color: "primary.main" }}>
            Music
          </Typography>
        </Grid>
        <Grid container spacing={1} p={{xs: 1, sm:2}} sx={gridContainerStyles}>
          {/* Map over music acknowledgments */}
          {musicAcknowledgements.map((track) => (
            <React.Fragment key={track.name}>
              <Grid item xs={4}>
                <Link
                  href={track.link}
                  underline="always"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={linkStyles}
                >
                  <Typography variant="subtitle1">{track.name}</Typography>
                </Link>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Typography sx={{ textAlign: "center" }}>
                  {track.artist}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Link
                  href={track.license}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={linkStyles}
                >
                  <Typography variant="subtitle1" sx={centeredTextStyles}>
                    License
                  </Typography>
                </Link>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>

      {/* Open Source Attributions Table */}
      <Box>
        <Grid container justifyContent="center" sx={{ mb: 2, mt: 6 }}>
          <Typography variant="h4" sx={{ color: "primary.main" }}>
            Open Source
          </Typography>
        </Grid>{" "}
        <Grid container spacing={1} p={{xs: 1, sm:2}} sx={gridContainerStyles}>
          {/* Map over open source attributions */}
          {openSourceAttributions.map((item) => (
            <React.Fragment key={item.name}>
              <Grid item xs={6}>
                <Link
                  href={item.repo}
                  underline="always"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={linkStyles}
                >
                  <Typography variant="subtitle1" sx={centeredTextStyles}>
                    {item.name}
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Link
                  href={item.license}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={linkStyles}
                >
                  <Typography variant="subtitle1" sx={centeredTextStyles}>
                    License
                  </Typography>
                </Link>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default Attributions;
