import Faq from 'pages/Support/HelpCenter/sections/Faq'
import React from 'react'

function Information() {
  return (
    <div>Information
        <Faq></Faq>
    </div>
  )
}

export default Information