import { useAuthenticator } from "@aws-amplify/ui-react";
import CustomAuthenticator from "components/CustomAuthenticator";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const paymentUrls = {
  standard: {
    monthly: "https://buy.stripe.com/test_8wMaF18oZ24569q009",
    annual: "https://buy.stripe.com/test_aEU00nbBbbEF7dudR0",
  },
  premium: {
    monthly: "https://buy.stripe.com/test_5kAfZlcFf101dBS8wH",
    annual: "https://buy.stripe.com/test_cN2eVhdJj38941i7sE",
  },
};

function Signup() {
  const { tier, duration } = useParams();
  const { user } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    if (user) {
      const PaymentUrl = paymentUrls[tier][duration];
      if (PaymentUrl) {
        window.location.replace(`${PaymentUrl}?prefilled_email=${encodeURIComponent(user.attributes.email)}`);
      }
    } else {
      console.log("Unauthenticated user attempted to sign up")
    }
  }, [tier, duration, user]);

  return <CustomAuthenticator></CustomAuthenticator>;
}

export default Signup;
