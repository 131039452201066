import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Grid,
} from "@mui/material";

function Features() {
  const primaryTextStyle = {
    color: "primary",
    fontSize: "1.5rem",
  };

  const secondaryTextStyle = {
    color: "secondary",
  };

  return (
    <Box className="App-body">
      <Grid container justifyContent="center">
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Typography
            align="center"
            variant="h4"
            color="primary"
            sx={{ marginBottom: "1rem" }}
          >
            Key Features
          </Typography>

          <List sx={{ marginBottom: "2rem" }}>
            <ListItem disablePadding>
              <ListItemText
                primaryTypographyProps={primaryTextStyle}
                secondaryTypographyProps={secondaryTextStyle}
                primary="Unparalleled Sound Separation Results"
                secondary="Immerse yourself in pristine audio quality as our intelligent algorithms disentangle even the most complex soundscapes. Uncover 
                hidden details, isolate vocals, instruments, and other sound sources with exceptional precision. The SoundShatter platform excels in producing 
                high-quality professional sound separation results. With advanced algorithms and cutting-edge technology, you can isolate, enhance, and manipulate 
                audio elements with remarkable precision. From audio enthusiasts to professionals, our platform caters to all sound-related needs."
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                primaryTypographyProps={primaryTextStyle}
                secondaryTypographyProps={secondaryTextStyle}
                primary="Low Cost, Maximum Value"
                secondary="We believe that exceptional sound separation should be accessible to all. Our platform offers a cost-effective solution without 
                compromising on quality. Unlock the power of professional audio separation without breaking the bank."
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                primaryTypographyProps={primaryTextStyle}
                secondaryTypographyProps={secondaryTextStyle}
                primary="Uninterrupted Availability"
                secondary="Enjoy the convenience of 24x7 worldwide access. Our platform is always available, ready to transform your audio with 
                unrivaled accuracy and speed. No matter where you are or what time it is, you can harness the power of sound separation at your convenience."
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                primaryTypographyProps={primaryTextStyle}
                secondaryTypographyProps={secondaryTextStyle}
                primary="Intuitive User Interface"
                secondary="Our user-friendly interface ensures a seamless experience from start to finish. With sleek black backgrounds, crisp white
                 text, and vibrant blue highlights, the interface is visually striking and easy on the eyes. Navigate effortlessly and achieve exceptional 
                 results in no time."
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                primaryTypographyProps={primaryTextStyle}
                secondaryTypographyProps={secondaryTextStyle}
                primary="State-Of-The-Art AI"
                secondary="Powered by the latest advancements in artificial intelligence and deep learning, our platform stays at the forefront of 
                audio processing innovation. Benefit from the most cutting-edge algorithms that continually improve and adapt to provide you with 
                the best possible sound separation results."
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                primaryTypographyProps={primaryTextStyle}
                secondaryTypographyProps={secondaryTextStyle}
                primary="Persistent Storage"
                secondary="Our platform provides a user library that offers secure and reliable persistent storage for your uploaded files and 
                sound separation results. Easily access and manage your audio assets, collaborate with others, and organize your projects with ease."
              />
            </ListItem>
          </List>

          <Typography
            variant="body1"
            align="center"
            sx={{ marginBottom: "2rem", fontWeight: "bold" }}
          >
            Join our global community of passionate sound enthusiasts,
            musicians, podcasters, and content creators who rely on SoundShatter
            to elevate their audio content!
          </Typography>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </Box>
  );
}

export default Features;
