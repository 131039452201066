import { createSlice } from "@reduxjs/toolkit";

const audioFilesSlice = createSlice({
  name: "audioFiles",
  initialState: {},
  reducers: {
    addAudioFile(state, action) {
      state[action.payload.key] = action.payload;
    },

    removeAudioFile(state, action) {
      delete state[action.payload];
    },

    addSeparation(state, action) {
      const { separationKey, separation } = action.payload.newSeparation;
      const sourceKey = separation.sourceKey;

      // Ensure the source object exists and initialize separations if needed
      if (!state[sourceKey]) {
        console.error(`Source key ${sourceKey} does not exist`);
        return;
      }

      // Ensure the separations object exists (it's initialized empty, but should exist)
      if (!state[sourceKey].separations) {
        console.error(`No separations found for source key ${sourceKey}`);
        return;
      }

      state[sourceKey].separations[separationKey] = separation;
    },

    updateSeparation(state, action) {
        const { pendingSeparationKey, separation } = action.payload.newSeparation;
        const { sourceKey, stems, status, zipKey, size } = separation;

      // Ensure the source object exists
      if (!state[sourceKey]) {
        console.error(`Source key ${sourceKey} does not exist`);
        return;
      }

      // Ensure the separations object exists (it's initialized empty, but should exist)
      if (!state[sourceKey].separations) {
        console.error(`No separations found for source key ${sourceKey}`);
        return;
      }

      // Ensure the specific separation exists
      if (!state[sourceKey].separations[pendingSeparationKey]) {
        console.error(`Pending separation key ${pendingSeparationKey} does not exist`);
        return;
      }

      // Update the separation fields
      if (stems !== undefined) {
        state[sourceKey].separations[pendingSeparationKey].stems = stems;
      }
      if (status !== undefined) {
        state[sourceKey].separations[pendingSeparationKey].status = status;
      }
      if (zipKey !== undefined) {
        state[sourceKey].separations[pendingSeparationKey].zipKey = zipKey;
      }
      if (size !== undefined) {
        state[sourceKey].separations[pendingSeparationKey].size = size;
      }
    },

    removeSeparation(state, action) {
      const { fullSeparationKey, sourceKey } = action.payload;

      // Ensure the source object and separations exist before attempting to delete
      if (state[sourceKey] && state[sourceKey].separations) {
        delete state[sourceKey].separations[fullSeparationKey];
      }
    },
  },
});

export const {
  addAudioFile,
  removeAudioFile,
  addSeparation,
  updateSeparation,
  removeSeparation,
} = audioFilesSlice.actions;

export default audioFilesSlice.reducer;
