// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

function Features() {
  return (
    <MKBox
    sx={{
        backgroundColor: "black.main",
        py: { xs: 6, lg: 8 },
        textAlign: "center",
      }}
      component="section"
    >
      <MKTypography variant="h2" color="warning" textGradient sx={{mt:10, mb:10}}>
        Highlighted Features
      </MKTypography>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard 
              direction= {{ xs: "center", sm: "left" }}
              title="Unparalleled Results"
              description="Experience the extraordinary potential of SoundShatter's cutting-edge technology. Delve into a world of enhanced clarity and depth, where vocals, instruments, and other sound sources are meticulously isolated with unmatched precision."
              small
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              direction= {{ xs: "center", sm: "left" }}
              title="Low Cost, Maximum Value"
              description="SoundShatter believes that exceptional sound separation should be accessible to all. Our platform offers a cost-effective solution without compromising on quality. Unlock the power of professional audio separation without breaking the bank."
              small
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              direction= {{ xs: "center", sm: "left" }}
              title="Cutting-Edge AI technology"
              description="Leveraging the latest breakthroughs in artificial intelligence and deep learning, our platform remains at the forefront of audio processing innovation. Experience the future of sound separation today."
              small
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              direction= {{ xs: "center", sm: "left" }}
              title="Intuitive Design"
              description="Experience a seamless journey from start to finish with our intuitive interface. Featuring sleek black backgrounds, crisp white text, and vibrant blue/orange highlights, our visually striking design ensures effortless navigation and exceptional results in no time."
              small
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              direction= {{ xs: "center", sm: "left" }}
              title="Uninterrupted Availability"
              description="Enjoy the convenience of 24x7 worldwide access. Our platform is always available, ready to transform your audio with unrivaled accuracy and speed. No matter where you are or what time it is, SoundShatter is there to help."
              small
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              direction= {{ xs: "center", sm: "left" }}
              title="Persistent Storage"
              description="SoundShatter provides a library for each user that offers secure and reliable persistent storage for your uploaded files and sound separation results. Easily access and manage your audio assets."
              small
            />
          </Grid>
          <Grid sx={{display: "none"}} item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              direction= {{ xs: "center", sm: "left" }}
              title="Custom Multitrack Player"
              description="Immerse yourself in music like never before with our custom multitrack player. Enjoy beautifully rendered waveforms, individual stem controls, master volume/mute, and an intuitive timeline (with draggable play cursor) for seamless playback and audio customization."
              small
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Features;
