import { Box, Typography } from "@mui/material";

function Profile() {
  return (
    <Box className="App-body">
        <Typography variant="h1" color="white.main">Profile</Typography>
    </Box>
  );
}

export default Profile;
