/********************************************************************************************
 * Helper routines to call SoundShatter API functions.
 */

import { API } from "aws-amplify";
import { GetCognitoIdentityId } from "utils";

async function isActionAllowed(action) {
    try {
      const cognitoId = await GetCognitoIdentityId();
      const { success } = await API.post(
        `soundshatterapi`,
        `/users/${cognitoId}/${action}`
      );
      return success;
    } catch (error) {
      console.error(
        `${action} API call failed. Error: ${error.message || error}`
      );
      return false;
    }
  }
  
  export const areDownloadsAllowed = () => isActionAllowed("downloadsallowed");
  export const areUploadsAllowed = () => isActionAllowed("uploadsallowed");