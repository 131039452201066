import React from "react";
import { Storage } from "aws-amplify";
import { Box, Grid, Typography } from "@mui/material";
import FileList from "../FileList";
import LibraryInfoCard from "components/Cards/LibraryInfoCard";
import { useNavigate } from "react-router-dom";
import CustomAuthenticator from "components/CustomAuthenticator";
import { useDispatch, useSelector } from "react-redux";
import { removeAudioFile } from "../../redux/slices/AudioFilesSlice";

function Library() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fileList = useSelector((state) => state.audioFiles);

  // Delete a library file from S3 storage
  const handleDelete = async (file, separations) => {
    try {
      const deletePromises = [];

      deletePromises.push(Storage.remove(file.key, { level: "private" }));

      for (const separation of separations) {
        deletePromises.push(
          Storage.remove(separation.key, { level: "private" })
        );
      }

      await Promise.all(deletePromises);

      dispatch(removeAudioFile(file.key));
    } catch (error) {
      console.log("Error deleting files:", error);
    }
  };

  // Navigate to separations page to allow user to work with a particular file.
  const handleFileClick = async (event, file) => {
    navigate("/Library/Separations", { state: { file } });
  };

  return (
    <CustomAuthenticator>
      <Grid sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
        <Grid
          item
          p={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "flex-end",
            minHeight: "20vh",
          }}
        >
          <Typography sx={{ color: "primary.main" }} variant="h5" gutterBottom>
            {Object.keys(fileList).length} songs
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            height: { xs: "40vh", md: "50vh" },
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            flex: 1,
          }}
        >
          <Box sx={{ minWidth: "60vw", overflow: "auto" }}>
            <FileList
              files={fileList}
              onDelete={handleDelete}
              onClick={handleFileClick}
            />
          </Box>
        </Grid>
        <Grid
          item
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            width: "100%",
            mb: { xs: 0, sm: 5 },
          }}
        >
          <LibraryInfoCard
            title="Library Usage"
            description="Information about your current library usage"
            direction="center"
            fileList={fileList}
          />
        </Grid>
      </Grid>
    </CustomAuthenticator>
  );
}

export default Library;
