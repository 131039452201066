import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import WaveSurfer from "wavesurfer.js";
import WaveSurferRegionsPlugin from "wavesurfer.js/src/plugin/regions";
import WaveSurferTimeLinePlugin from "wavesurfer.js/src/plugin/timeline";
import { WaveformHeight } from "const";

const formWaveSurferOptions = (ref) => ({
  container: ref,
  waveColor: "lightskyblue",
  progressColor: "#0077ff",
  cursorColor: "#0077ff",
  responsive: true,
  height: WaveformHeight,  
  // If true, normalize by the maximum peak instead of 1.0.
  normalize: true,
  // Use the PeakCache to improve rendering speed of large waveforms.
  partialRender: true,
  // backend: 'MediaElement',  - draws a thin line while waveform being calcd, allows immediate playback.  Doesn't really work well
  // for multitrack through - some stems will start before others are available.  Maybe if you want until all are available to allow
  // playback, but that may defeat some of the entire purpose.  Might still shave off some time until file is playable though if these
  // issues can be overcome.
  interact: false, // don't respond to mouse clicks/drags, etc.
  hideScrollbar: true,
  plugins: [
    WaveSurferTimeLinePlugin.create({
      container: "#wave-timeline",
      primaryFontColor: "#ffffff",
      secondaryFontColor: "#ffffff",
      secondaryColor: "#ffffff",
      timeInterval: 30,
      notchPercentHeight: 20
    }),
  ],
});

export default function Waveform({
  url,
  volume,
  playbackCommand,
  playbackPosition,
  isMuted,
  onAudioProcess,
  onDuration,
  onStemReady
}) {
  const waveformContainerRef = useRef(null);
  const waveformInstance = useRef(null);
  const [loading, setLoading] = useState(false);

  // create new WaveSurfer instance on component mount and when url changes
  useEffect(() => {
    if (waveformContainerRef.current) {
      const options = formWaveSurferOptions(waveformContainerRef.current);
      waveformInstance.current = WaveSurfer.create(options);
      setLoading(true);
      waveformInstance.current.load(url);

      // event handlers
      waveformInstance.current.on("audioprocess", onAudioProcess);
      waveformInstance.current.on("ready", function () {
        // make sure object still available when file loaded
        if (waveformInstance.current) {
          waveformInstance.current.setVolume(volume);
          onStemReady(url);
          onDuration(waveformInstance.current.getDuration());
          setLoading(false)
        }
      });
      waveformInstance.current.on('error', function (errorMsg) {
        console.error('An error occurred while loading a sound file into WaveSurfer:', errorMsg);
      });
      
      // Removes events, elements and disconnects Web Audio nodes.
      // when component unmount
      return () => waveformInstance.current.destroy();
    }
  }, [url]);

  useEffect(() => {
    if (waveformInstance.current) {
      waveformInstance.current.setVolume(volume);
    }
  }, [volume]);

  useEffect(() => {
    if (waveformInstance.current) {
      waveformInstance.current.setMute(isMuted);
    }
  }, [isMuted]);

  useEffect(() => {
    if (playbackCommand === "play" || playbackCommand === "pause") {
      if (waveformInstance.current) {
        waveformInstance.current.playPause();
      }
    } else if (playbackCommand === "reset") {
      waveformInstance.current.stop();
      waveformInstance.current.seekTo(0);
    }
  }, [playbackCommand]);

  useEffect(() => {
    if (waveformInstance.current) {
      waveformInstance.current.seekTo(playbackPosition);
    }
  }, [playbackPosition]);

  return <Box ref={waveformContainerRef} sx={{ flex: 1 }} />;
}
