import {
  Box,
  Button,
  CircularProgress,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Slide,
} from "@mui/material";
import { API } from "aws-amplify";
import { useState } from "react";

import awsexport from "aws-exports";
import MKTypography from "components/MKTypography";
import { GetCognitoIdentityId, GetKeyFromFile } from "utils";
import QuotaExceededDialog from "./QuotaExceeded";

const paperStyle = {
  style: {
    backgroundColor: "#333333",
    borderRadius: "8px",
  },
};

const methodOptions = {
  demucs: [
    "htdemucs",
    "htdemucs_ft",
    "htdemucs_6s",
    "hdemucs_mmi",
    "mdx",
    "mdx_extra",
    "mdx_q",
    "mdx_extra_q",
  ],
  spleeter: ["spleeter:2stems", "spleeter:4stems", "spleeter:5stems"],
  openunmix: ["umxl", "umxhq", "umx", "umxse"],
};

const SoundSeparationDialog = ({ open, file, onClose, onCancel }) => {
  const [method, setMethod] = useState("demucs");
  const [model, setModel] = useState("htdemucs");
  const [showProgress, setShowProgress] = useState(false);
  const [createZip, setCreateZip] = useState(false);
  const [openQuotaExceededDialog, setOpenQuotaExceededDialog] = useState(false);

  const handleMethodChange = (event) => {
    const selectedMethod = event.target.value;
    setMethod(selectedMethod);

    const firstModel = methodOptions[selectedMethod][0]; // Get the first model value for the selected method
    setModel(firstModel);
  };

  const handleModelChange = (event) => {
    setModel(event.target.value);
  };

  const postSeparationRequest = async (postData) => {
    const sub = await GetCognitoIdentityId();
    const results = await API.post(
      "soundshatterapi",
      `/users/${sub}/library/separation`,
      {
        body: postData,
      }
    );

    if (results.success) {
      return results.data.tasks[0].taskArn;
    } else {
      // display error?  retry?  Several retries are done automatically, so..
    }
  };

  const handleSubmit = async () => {
    try {
      setShowProgress(true);
      const sub = await GetCognitoIdentityId();

      const response = await API.post(
        "soundshatterapi",
        `/users/${sub}/uploadsallowed`
      );
      console.log(`UploadAllowed response: ${JSON.stringify(response)}`);

      if (response.success) {
        const postData = {
          s3_key: `${await GetKeyFromFile(file)}`,
          bucket_name: awsexport.aws_user_files_s3_bucket,
          separation_method: method,
          model: model,
          create_zip: createZip.toString(),
        };

        const Arn = await postSeparationRequest(postData);

        setShowProgress(false);

        onClose({
          key: file.key,
          taskArn: Arn,
          title: file.metadata.title,
          status: "PENDING",
          method: method,
          model: model,
        });
      }
    } catch (error) {
      if (error.response.status === 403) {
        console.log("Separation not allowed - Quota would be exceeded");
        setOpenQuotaExceededDialog(true); // open QuotaExceededDialog
      }
    }
  };

  const handleSeparationCancel = () => {
    onCancel();
  };
  const handleQuotaExceededClose = () => {
    setOpenQuotaExceededDialog(false);
    setShowProgress(false);
  };

  const renderMethodOptions = () => {
    const options = methodOptions[method];
    return options.map((option) => (
      <MenuItem key={option} value={option}>
        {option}
      </MenuItem>
    ));
  };

  return (
    <Dialog
      open={open}
      fullWidth
      TransitionComponent={Slide}
      transitionDuration={150}
      TransitionProps={{ direction: "down" }}
      PaperProps={paperStyle}
    >
      <DialogTitle align="center" sx={{ color: "primary.main" }}>
        New Separation
      </DialogTitle>
      <DialogContent>
        <MKTypography sx={{ mt: 5, color: "white.main", align: "center" }}>
          Track: { decodeURIComponent(file?.metadata?.title) }
        </MKTypography>
        <MKTypography sx={{ color: "white.main", align: "center" }}>
          Album: { decodeURIComponent(file.metadata.album) }
        </MKTypography>
        <MKTypography sx={{ color: "white.main", align: "center" }}>
          Artist: { decodeURIComponent(file.metadata.artist) }
        </MKTypography>
        <FormControl sx={{ mt: 5 }} fullWidth margin="dense">
          <InputLabel id="method-label">Method</InputLabel>
          <Select
            labelId="method-label"
            id="method-select"
            value={method}
            onChange={handleMethodChange}
            label="Method"
            sx={{
              "& .MuiInputBase-input.MuiOutlinedInput-input": {
                color: "white.main",
              },
            }}
          >
            <MenuItem value="demucs">Demucs</MenuItem>
            <MenuItem value="spleeter">Spleeter</MenuItem>
            <MenuItem value="openunmix">OpenUnmix</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ mt: 3 }} fullWidth margin="dense">
          <InputLabel id="model-label">Model</InputLabel>
          <Select
            labelId="model-label"
            id="model-select"
            value={model}
            onChange={handleModelChange}
            label="Model"
            sx={{
              "& .MuiInputBase-input.MuiOutlinedInput-input": {
                color: "#ffffff",
              },
            }}
          >
            {renderMethodOptions()}
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={createZip}
              onChange={(event) => setCreateZip(event.target.checked)}
              color="primary"
            />
          }
          align="center"
          label={
            <MKTypography color="primary">Create Downloadable Zip</MKTypography>
          }
          sx={{ mt: 5 }}
        />
      </DialogContent>
      <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
        {showProgress && (
          <CircularProgress sx={{ mt: 5, width: "60%" }} color="primary" />
        )}
      </Box>
      <Box sx={{ mt: 5 }}>
        <Box sx={{ display: "flex", justifyContent: "space-evenly", mb: 5 }}>
          <Button
            onClick={handleSeparationCancel}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            width="80"
            onClick={handleSubmit}
            sx={{ backgroundColor: "warning.main", color: "white.main" }}
            variant="contained"
            disabled={!method || !model}
          >
            Submit
          </Button>
        </Box>
      </Box>
      <QuotaExceededDialog
        open={openQuotaExceededDialog}
        onClose={handleQuotaExceededClose}
        file={file}
      />
    </Dialog>
  );
};

export default SoundSeparationDialog;
