import React, { useState, useEffect, useRef } from "react";
import Waveform from "./Waveform";
import { Grid, Box, Typography, Link } from "@mui/material";
import PlayCursor from "./PlayCursor";
import TrackControl from "./TrackControl";
import MultitrackMasterControls from "./MultitrackMasterControls";
import MKTypography from "./MKTypography";
import { RewindForwardIncrement } from "const";

const MetadataDisplay = ({ metadata, attribution }) => {
  return (
    <Box sx={{ color: "white.main" }} align="center">
      <Typography sx={{ color: "white.main", mt: 3 }} variant="subtitle1">
        Title: { decodeURIComponent(metadata.title) }
      </Typography>
      <Typography variant="subtitle2">Artist: { decodeURIComponent(metadata.artist)}</Typography>
      <Typography variant="subtitle2">Album: { decodeURIComponent(metadata.album)}</Typography>
      {attribution && 
        (
          <Typography variant="subtitle2" sx={{color: "warning.main"}}>
            <Link href={attribution} sx={{color: "warning.main"}} underline="none">
              Attribution
            </Link>
          </Typography>
        )
      }
    </Box>
  );
};

const MultitrackPlayer = ({ metaData, attributionUrl, stemUrls }) => {
  const [state, setState] = useState({
    metadata: metaData,
    attribution: attributionUrl,
    audioPosition: 0, // current position of audio file
    seekPosition: 0, // new audio position to seek to, for example when user drag/drops the playcursor to a new position.
    stems: stemUrls.map((url) => ({
      url: url,
      volume: 25,
      isMuted: false,
      isPlaying: false,
      isReady: false,
      waveform: null,
    })),
    masterVolume: 25,
    masterMute: false,
    trackDuration: 0,
    isPlaying: false,
    isReady: false,
  });

  // Create a ref to keep track of the previous "stems" value
  const prevStemsRef = useRef(state.stems);

  // useEffect to compare the previous "stems" value with the current value
  useEffect(() => {
    if (prevStemsRef.current !== state.stems) {
      console.log("At least one stem was updated:", state.stems);
    }

    // Update the prevStemsRef with the current value for the next render
    prevStemsRef.current = state.stems;
  }, [state.stems]);

  // Create a ref for each waveform instance
  const [waveformRefs, setWaveformRefs] = useState([]);
  const [reset, setReset] = useState(false);

  // Update waveformRefs.current whenever stemUrls changes
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      metadata: metaData,
      attribution: attributionUrl,
      audioPosition: 0, // current position of audio file
      seekPosition: 0, // new audio position to seek to, for example when user drag/drops the playcursor to a new position.
      stems: stemUrls.map((url) => ({
        url,
        volume: 25,
        isMuted: false,
        isPlaying: false,
        isReady: false,
        waveform: null,
      })),
      masterVolume: 25,
      masterMute: false,
      trackDuration: 0,
      isPlaying: false,
      isReady: false,
    }));
    setPlaybackCommand("reset");
    setReset(true);
  }, [stemUrls, metaData, attributionUrl]);

  useEffect(() => {
    if (!reset) {
      return;
    }
    // Reset the embedded component here
    setReset(false);
  }, [reset]);

  // State for the playback command (play/pause/replay/rewind/forward)
  const [playbackCommand, setPlaybackCommand] = useState("pause");

  // Handle play/pause/replay button clicks
  const onPlaybackIconClick = (command) => {
    setState((prevState) => ({
      ...prevState,
      isPlaying: command === "play",
    }));

    if (command === "rewind") {
      const rewindPosition = state.audioPosition - RewindForwardIncrement; 
      const seekPosition = Math.max(0.0, rewindPosition); // Ensure seek position is not negative
      console.log(`seekPosition: ${state.seekPosition}`)
      setState((prevState) => ({
        ...prevState,
        seekPosition: seekPosition,
      }));
    } else if (command === "forward") {
      const forwardPosition = state.audioPosition + RewindForwardIncrement; 
      const seekPosition = Math.min(0.999999999999999, forwardPosition); // Ensure seek position is not greater than 1 (end of audio)
      setState((prevState) => ({
        ...prevState,
        seekPosition: seekPosition,
      }));
    } else if (command === "reset") {
       setState((prevState) => ({
        ...prevState,
        audioPosition: 0,
        seekPosition: 0,
      }));
    }

    setPlaybackCommand(command);
  };

  // Handle master volume slider changes
  const onMasterVolumeChange = (event, value) => {
    setState((prevState) => ({ ...prevState, masterVolume: value }));
  };

  // Handle master volume slider changes
  const onMasterMuteToggle = () => {
    const newMute = !state.masterMute;
    const updatedStems = state.stems.map((stem) => ({
      ...stem,
      isMuted: newMute,
    }));
    setState((prevState) => ({
      ...prevState,
      stems: updatedStems,
      masterMute: newMute,
    }));
  };

  const handleSeekPositionChange = (position) => {
    setState((prevState) => ({ ...prevState, seekPosition: position }));

    // done because the seek position may be changed before the stems begin playing.  In that case
    // the playbackCommand was likely still equal to "reset" and doesn't cause a rerender.
    setPlaybackCommand("drop");
  };

  const onVolumeChange = (index, newVolume) => {
    setState((prevState) => {
      const updatedStems = [...prevState.stems];
      updatedStems[index] = {
        ...updatedStems[index],
        volume: newVolume,
      };
      return { ...prevState, stems: updatedStems };
    });
  };

  const onMuteChange = (index, value) => {
    setState((prevState) => {
      const updatedStems = [...prevState.stems];
      const stem = updatedStems[index];
      stem.isMuted = value;
      return {
        ...prevState,
        stems: updatedStems,
      };
    });
  };

  const onAudioProcess = (event) => {
    setState((prevState) => ({
      ...prevState,
      audioPosition: event / prevState.trackDuration,
    }));
  };

  const onDuration = (stemDuration) => {
    if (stemDuration > state.trackDuration) {
      setState((prevState) => ({ ...prevState, trackDuration: stemDuration }));
    }
  };

  const onStemReady = (url) => {
    setState((prevState) => {
      const updatedStems = prevState.stems.map((stem) => {
        if (stem.url === url) {
          return { ...stem, isReady: true };
        }
        return stem;
      });
      const allReady = updatedStems.every((stem) => stem.isReady);
      return { ...prevState, stems: updatedStems, isReady: allReady };
    });
  };

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');
    
    let timeString = `${formattedMinutes}:${formattedSeconds}`;
    
    if (hours > 0) {
      const formattedHours = hours.toString();
      timeString = `${formattedHours}:${timeString}`;
    }
    
    return timeString;
  };
  
  return (
    <React.Fragment>
      {!(stemUrls.length === 0) && (
        <Box id="playback-container">
          <Grid container spacing={2}>
            <Grid item xs={2} sx={{ paddingTop: "20px", paddingLeft: "0px", minWidth: "100px" }}>
              <Grid item sx={{ padding: "0", height: "20px", mb: {xs: 1, lg: 2}, mt: { xs: 1, lg: 0} }}>
                <MKTypography sx={{ typography: { lg: 'body2', xs: 'caption' },color: "white.main", textAlign: "center", width: "100%" }}>
                    {state.audioPosition === Infinity ? "00:00" : formatTime(state.audioPosition * state.trackDuration)} / {formatTime(state.trackDuration)}
                </MKTypography>
              </Grid> 
              {/* Track Controls */}
              {state.stems.map((stem, index) => (
                <TrackControl
                  key={`trackcontrol_${index}`}
                  stem={stem}
                  index={index}
                  masterVolume={state.masterVolume}
                  masterMute={state.masterMute}
                  onVolumeChange={onVolumeChange}
                  onMuteChange={onMuteChange}
                  isReady={state.isReady}
                  reset={reset}
                />
              ))}
            </Grid>

            <Grid item xs sx={{ paddingTop: "20px", paddingLeft: "0px" }}>
              {/* Box with position: relative to contain both timeline and waveforms.  Parents absolutely positioned playcursor. */}
              <Box sx={{ position: "relative" }}>
                <Box id="wave-timeline" sx={{ mb: 2 }}></Box>
                <PlayCursor
                  audioPosition={state.audioPosition}
                  playbackCommand={playbackCommand}
                  onDrop={handleSeekPositionChange}
                />
                {state.stems.map((stem, index) => (
                  <Waveform
                    key={`waveform_${index}`}
                    url={stem.url}
                    ref={waveformRefs[index]}
                    volume={stem.volume / 100}
                    playbackCommand={playbackCommand}
                    playbackPosition={state.seekPosition}
                    isMuted={stem.isMuted}
                    onAudioProcess={onAudioProcess}
                    onDuration={onDuration}
                    onStemReady={onStemReady}
                  />
                ))}
              </Box>
            </Grid>
            {/* Metadata Display and Multitrack Master Controls */}
            <Grid item xs={12}>
              <Grid container spacing={2} direction="row" wrap="nowrap">
                <Grid item xs={false} sm={2} sx={{
                    paddingTop: "20px",
                    paddingLeft: "0px",
                    minWidth: "100px",
                    display: { xs: "none", sm: "block" },
                  }}
                />

                {/* Multitrack Master Controls */}
                <Grid item xs={12} sm={10}>
                  <MultitrackMasterControls
                    masterVolume={state.masterVolume}
                    audioPosition={state.audioPosition}
                    isPlaying={state.isPlaying}
                    isMuted={state.masterMute}
                    onMasterVolumeChange={onMasterVolumeChange}
                    onMasterSeekPositionChange={handleSeekPositionChange}
                    onMasterMuteToggle={onMasterMuteToggle}
                    onPlaybackIconClick={onPlaybackIconClick}
                    isReady={state.isReady}
                    reset={playbackCommand === "reset"}
                  />
                </Grid>
              </Grid>
              {/* Metadata Display */}
              <Grid item xs={false} sm={12} container flex
                alignItems={{
                  xs: "center",
                  sm: "flex-start",
                }}
                justifyContent={{
                  xs: "center",
                  sm: "flex-start",
                }}
              >
                <MetadataDisplay metadata={state.metadata} attribution={state.attribution}/>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </React.Fragment>
  );
};

export default MultitrackPlayer;
