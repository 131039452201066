import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

const StyledDropzoneContainer = styled(Box)(({ theme }) => ({
  border: '2px dashed #ffffff',
  borderRadius: '5px',
  padding: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  width: "100%",
  height: "100%",
  transition: 'border .24s ease-in-out',
  '&:hover': {
    borderColor: '#0077ff',
  }
}));

function FileDropzone({ onDrop }) {

  const onDropHandler = useCallback((acceptedFiles) => {
    if (onDrop) {
      onDrop(acceptedFiles);
    }
  }, [onDrop]);

   const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: onDropHandler });

  return (
    <StyledDropzoneContainer {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <Typography sx={{ fontSize: 'calc(10px + 2vmin)' }}>Drop your files here...</Typography>
      ) : (
        <Typography sx={{ fontSize: 'calc(10px + 2vmin)' }}>Drag and drop or click to select files</Typography>
      )}
    </StyledDropzoneContainer>
  );
}

export default FileDropzone;
