import { React, useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Hub, Amplify, API, Auth } from "aws-amplify";
import awsExports from "./aws-exports";
import { Authenticator } from "@aws-amplify/ui-react";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import Pricing from "./components/Pages/Pricing";
import Features from "./components/Pages/Features";
import News from "./components/Pages/News";
import Account from "./components/Pages/Account";
import Profile from "./components/Pages/Profile";
import Upload from "./components/Pages/Upload";
import Library from "./components/Pages/Library";
import Home from "./components/Pages/Home";
import Privacy from "./components/Pages/Privacy";
import TermsOfUse from "./components/Pages/TermsOfUse";
import Error404 from "./components/ErrorPages/Error404";
import Signup from "components/Pages/Signup";
import Information from "components/Pages/Information";
import PaymentConfirmation from "components/Pages/PaymentConfirmation";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Box } from "@mui/material";
import theme from "assets/theme";
import { IsTaskRunning } from "utils";
import { DefaultCognitoCustomDomain } from "const";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import Attributions from "components/Pages/Attributions";
import FileSeparations from "components/Pages/FileSeparations";
import { useDispatch, useSelector } from 'react-redux';
import { UpdatePendingSeparation } from "utils";
import { SyncAllFromCloud } from "CloudSync";

// Need to pick a single signIn and signOut uri from among the three configured.  Uses the origin of the page which is unique per environment.
awsExports.oauth.redirectSignIn = `${window.location.origin}/`;
awsExports.oauth.redirectSignOut = `${window.location.origin}/`;

// should exist in env collection (see amplify console build settings and environment variables). For local builds, default to develop environment
awsExports.oauth.domain =
  process.env.REACT_APP_COGNITO_CUSTOM_DOMAIN || DefaultCognitoCustomDomain;

Amplify.configure(awsExports);

async function InitApplication(fileList, dispatch) {
  for (const fileKey in fileList) {
    if (fileList.hasOwnProperty(fileKey)) {
      const file = fileList[fileKey];
      const separations = file.separations;
    
      for (const separationKey in separations) {
        if (separations.hasOwnProperty(separationKey)) {
          const separation = separations[separationKey];
    
          if (separation.status === 'Pending') {
            const taskRunning = await IsTaskRunning(separation.taskArn);
            if (!taskRunning) {
              await UpdatePendingSeparation(file, separation, dispatch)
            }
          }
        }
      }
    }
  }
}

async function SignUpUser(identityEmail) {
  try {
    const currentUser = await Auth.currentCredentials();
    const postData = {
      signupEmail: identityEmail, // remember the email associated with this user at signup time.
    };

    await API.post(
      "soundshatterapi",
      `/users/${currentUser.identityId}/auth/signup`,
      {
        body: postData,
      }
    );

    return;
  } catch (error) {
    console.log(JSON.stringify(error));
    throw error;
  }
}

const App = () => {
  const initialized = useRef(false);
  const fileList = useSelector((state) => state.audioFiles);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;

      // Run your one-time code here
      InitApplication(fileList, dispatch);

      // Listen for signin events. Ensure that we know about this user in the usertable.
      Hub.listen("auth", async ({ payload: { event, data } }) => {
        const handleAuthEvent = async () => {
          switch (event) {
            case "signUp":
              try {
                await SignUpUser(data.user.username);
              } catch (error) {
                console.error('Error signing up user:', error);
              }
              break;
            case "signIn":
              try {
                await SyncAllFromCloud(dispatch, data.username);
              } catch (error) {
                console.error('Error signing in user:', error);
              }
              break;
            case "signIn_failure":
              console.log("signIn failure: " + data);
              break;
            default:
              break;
          }
        };

        await handleAuthEvent();
      });
      
    }
  }, [fileList, dispatch]);


  return (
    <Authenticator.Provider>
     <ThemeProvider theme={theme}>
       <CssBaseline />
       <Box className="App-body">
        <Router>
           <ResponsiveAppBar />
           <Routes>
             <Route exact path="/" element={<Home />}></Route>
             <Route exact path="/home" element={<Home />}></Route>
             <Route exact path="/Library" element={<Library />}></Route>
             <Route exact path="/Library/Separations" element={<FileSeparations />}></Route>
             <Route exact path="/Pricing" element={<Pricing />}></Route>
             <Route exact path="/Features" element={<Features />}></Route>
             <Route exact path="/News" element={<News />}></Route>
             <Route exact path="/Account" element={<Account />}></Route>
             <Route exact path="/Profile" element={<Profile />}></Route>
             <Route exact path="/Privacy" element={<Privacy />}></Route>
             <Route exact path="/Information" element={<Information />}></Route>
             <Route exact path="/PaymentConfirmation" element={<PaymentConfirmation />}></Route>
             <Route exact path="/TermsOfUse" element={<TermsOfUse />}></Route>
             <Route exact path="/Upload" element={<Upload />}></Route>
             <Route exact path="/Attributions" element={<Attributions />}></Route>
             <Route exact path="/Signup/:tier/:duration" element={<Signup />}></Route>
             <Route exact path="*" element={<Error404 />}></Route>
           </Routes>
         </Router>
       </Box>
     </ThemeProvider>
    </Authenticator.Provider>
  );
}

export default App;
