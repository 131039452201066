// constants
export const AudioSeparationPath = "user/audio/output/separations/";
export const AudioUploadPath = "user/audio/uploads/";
export const WaveformHeight = 60;
export const xsWaveformHeight = 30;

export const MbSize = 1048576;

export const RewindForwardIncrement = 0.05;

export const SeparationStatus = Object.freeze({
  PENDING: 'Pending',
  COMPLETE: 'Complete',
  ERROR: 'error'
});
  
// Settings
export const DefaultCognitoCustomDomain = "develop-auth.soundshatter.com";  // change to "auth.soundshatter.com" if running master branch locally and using google sign in"
