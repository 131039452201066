import React, { useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Typography,
  Tooltip,
} from "@mui/material";
import "./FileList.css"; // import CSS file for styling
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import ConfirmDeleteSeparationDialog from "./Dialogs/ConfirmDeleteSeparationDialog";
import { GetFileName } from "utils";
import { useSelector } from 'react-redux'
import { GetPresignedUrlForFile } from "utils";
import { SeparationStatus } from "const";
import { areDownloadsAllowed } from "APIHelpers";

const SeparationListItem = ({
  separation,
  onDeleteClick,
  onItemClick,
  isSelected,
}) => {
  const onDelete = (event) => {
    event.stopPropagation(); // Prevent click event from bubbling, otherwise stems would be downloaded.
    if (
      typeof separation.status === "undefined" ||
      separation.status !== SeparationStatus.PENDING
    ) {
      onDeleteClick(separation);
    }
  };

  function separationPending(separation) {
    return separation?.status === SeparationStatus.PENDING
  }
  
  const onDownloadInternal = async (event) => {
    event.stopPropagation();
    if (separation.zipKey) {
      try {
        if (await areDownloadsAllowed()){
          const fileUrl = await GetPresignedUrlForFile(separation.zipKey, "private");
          window.open(fileUrl, "_blank");
        }
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    }
  };

  const onClickInternal = () => {
    if (
      typeof separation.status === "undefined" ||
      separation.status !== SeparationStatus.PENDING
    ) {
      const filteredStems = separation.stems.filter(
        (stem) => !stem.key.endsWith(".zip")) ;
      onItemClick(separation, filteredStems);
    }
  };

  const fileList = useSelector((state) => state.audioFiles);

  return (
    <Box sx={{ cursor: "pointer" }}>
        <Grid
          container
          key={GetFileName(separation)}
          sx={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: isSelected ? '#686060' : '#2f2f2f',
            alignItems: "center",
            p: {xs: 1, md: 2},
            boxShadow: 1,
            borderRadius: 4,
            '&:hover': {
                backgroundColor: '#686060', // Change this to the desired hover color
              },
          }}
          onClick={onClickInternal}
        >
          <Grid item xs={11} sx={{ display: {xs: "block", md: "flex"} }}>
              <Grid item xs={11} md={5} display={"flex"} flexGrow={1} sx={{ width: { xs: "100%" } }}>
                <Typography variant="body1" >
                  Title: {decodeURIComponent(fileList[separation.sourceKey].metadata.title)}
                </Typography>
                {separation.status && separation.status === SeparationStatus.PENDING && (
                  <Typography variant="body1" sx={{ mx: 1, color: "warning.main" }}>
                    {` (${separation.status})`}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={11} md={2} flexGrow={1} sx={{ width: { xs: "100%" } }}>
                <Typography variant="body1">
                  Method: {separation.method}
                </Typography>
              </Grid>
              <Grid item xs={11} md={4} flexGrow={1} sx={{ width: { xs: "100%" } }}>
                <Typography variant="body1">
                  Model: {separation.model}
                </Typography>
              </Grid>
              <Grid item xs={11} md={2} flexGrow={1} sx={{ width: { xs: "100%" } }}>
                <Typography variant="body1">
                  Size: { separation.status === SeparationStatus.COMPLETE
                    ? `${(separation.size / 1000000).toFixed(1)} Mb`
                    : "Unknown" }
                </Typography>
              </Grid>
          </Grid>
          <Grid item xs={1} sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: "flex-end", textAlign: "right" }}>
            <Grid item>
              <Tooltip title="Download stems">
                <DownloadIcon
                  color="primary"
                  onClick={!separationPending(separation) ? onDownloadInternal : () => {}}
                  sx={{
                    opacity: !separationPending(separation) && separation.zipKey ? 1 : 0.4,
                  }}
                />
              </Tooltip>
            </Grid>

            <Grid item>
              <Tooltip title="Delete">
                <DeleteIcon 
                  color="primary" 
                  onClick={!separationPending(separation) ? onDelete : () => {}}
                  sx={{
                    opacity: !separationPending(separation) ? 1 : 0.4,
                  }}
                />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
    </Box>
  );
};

const SeparationList = ({
  separationList,
  onDelete,
  onItemClick,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedSeparation, setSelectedSeparation] = useState(null);

  const handleDeleteClick = (separation) => {
    setSelectedSeparation(separation);
    setOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await onDelete(selectedSeparation);
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteCancel = () => {
    setOpen(false);
  };

  const handleClick = (separation, filteredSeparations) => {
    setSelectedSeparation(separation);
    onItemClick(filteredSeparations);
  };

  const extractMethodAndModel = (key) => {
    const parts = key.split('/');
    const methodModel = parts[parts.length - 2] + '/' + parts[parts.length - 1];
    return methodModel;
  };
  
  const customSeparationSort = (a, b) => {
    const methodModelA = extractMethodAndModel(a[0]);
    const methodModelB = extractMethodAndModel(b[0]);
    return methodModelA.localeCompare(methodModelB);
  };
  
  const separationEntries = Object.entries(separationList);    
  
  return (
    <Stack sx={{ flexGrow: 1 }}>
      <Stack spacing={3}>
        {separationList &&
          separationEntries
          .sort(customSeparationSort)
          .map(([key, separation]) => (
            <SeparationListItem
              key={key}
              separation={separation}
              onDeleteClick={handleDeleteClick}
              onItemClick={handleClick}
              isSelected={selectedSeparation === separation}
            />
          ))}
        <ConfirmDeleteSeparationDialog
          open={open}
          onClose={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
          separation={selectedSeparation}
        />
      </Stack>
    </Stack>
  );
};

export default SeparationList;
