import awsExports from "aws-exports";
import MKBox from "components/MKBox";
import Divider from "@mui/material/Divider";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MultitrackPlayer from "components/MultitrackPlayer";
import MKTypography from "components/MKTypography";
import Footer from "components/Sections/Footers/Footer";
import Banner from "./Landings/sections/Banner";
import Features from "./Landings/sections/Features";
import Testimonials from "./Landings/sections/Testimonials";
import Pricing from "./Landings/sections/Pricing";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";

const HomepageMusic = [
  {
    metadata: [
      { artist: "The Copycuts", title: "Lovers Like Neon", album: "Sketches", genre: "Pop" },
    ],
    attribution: "/Attributions",
    stemUrls: [
      [
        `https://${awsExports.aws_user_files_s3_bucket}.s3.amazonaws.com/site/homepage/music/Pop/bass.mp3`,
        `https://${awsExports.aws_user_files_s3_bucket}.s3.amazonaws.com/site/homepage/music/Pop/drums.mp3`,
        `https://${awsExports.aws_user_files_s3_bucket}.s3.amazonaws.com/site/homepage/music/Pop/other.mp3`,
        `https://${awsExports.aws_user_files_s3_bucket}.s3.amazonaws.com/site/homepage/music/Pop/vocals.mp3`,
      ],
    ],
  },
  {
    metadata: [{ artist: "Simon Panrucker", title: "Fruit Salad", album: "Hello My Name Is Simon Panrucker", genre: "Experimental Pop" }],
    attribution: "/Attributions",
    stemUrls: [
      [
        `https://${awsExports.aws_user_files_s3_bucket}.s3.amazonaws.com/site/homepage/music/Experimental%20Pop/bass.mp3`,
        `https://${awsExports.aws_user_files_s3_bucket}.s3.amazonaws.com/site/homepage/music/Experimental%20Pop/drums.mp3`,
        `https://${awsExports.aws_user_files_s3_bucket}.s3.amazonaws.com/site/homepage/music/Experimental%20Pop/other.mp3`,
        `https://${awsExports.aws_user_files_s3_bucket}.s3.amazonaws.com/site/homepage/music/Experimental%20Pop/vocals.mp3`,
      ],
    ],
  },
  {
    metadata: [{ artist: "Lorenzo's Music", title: "We All Fall Down", album: "Just Had To Let You Know", genre: "Indie-Rock" }],
    attribution: "/Attributions",
    stemUrls: [
      [
        `https://${awsExports.aws_user_files_s3_bucket}.s3.amazonaws.com/site/homepage/music/Indie-Rock/bass.mp3`,
        `https://${awsExports.aws_user_files_s3_bucket}.s3.amazonaws.com/site/homepage/music/Indie-Rock/drums.mp3`,
        `https://${awsExports.aws_user_files_s3_bucket}.s3.amazonaws.com/site/homepage/music/Indie-Rock/other.mp3`,
        `https://${awsExports.aws_user_files_s3_bucket}.s3.amazonaws.com/site/homepage/music/Indie-Rock/vocals.mp3`,
      ],
    ],
  },
  {
    metadata: [{ artist: "TenPenny Joke", title: "Caroline", album: "FrostWire", genre: "Rock" }],
    attribution: "/Attributions",
    stemUrls: [
      [
        `https://${awsExports.aws_user_files_s3_bucket}.s3.amazonaws.com/site/homepage/music/Rock/bass.mp3`,
        `https://${awsExports.aws_user_files_s3_bucket}.s3.amazonaws.com/site/homepage/music/Rock/drums.mp3`,
        `https://${awsExports.aws_user_files_s3_bucket}.s3.amazonaws.com/site/homepage/music/Rock/other.mp3`,
        `https://${awsExports.aws_user_files_s3_bucket}.s3.amazonaws.com/site/homepage/music/Rock/vocals.mp3`,
      ],
    ],
  },
  {
    metadata: [{ artist: "Bryan Mathys", title: "Daydreaming of The South", album: "Wild Heart", genre: "Country" }],
    attribution: "/Attributions",
    stemUrls: [
      [
        `https://${awsExports.aws_user_files_s3_bucket}.s3.amazonaws.com/site/homepage/music/Country/bass.mp3`,
        `https://${awsExports.aws_user_files_s3_bucket}.s3.amazonaws.com/site/homepage/music/Country/drums.mp3`,
        `https://${awsExports.aws_user_files_s3_bucket}.s3.amazonaws.com/site/homepage/music/Country/other.mp3`,
        `https://${awsExports.aws_user_files_s3_bucket}.s3.amazonaws.com/site/homepage/music/Country/vocals.mp3`,
        `https://${awsExports.aws_user_files_s3_bucket}.s3.amazonaws.com/site/homepage/music/Country/piano.mp3`,
        `https://${awsExports.aws_user_files_s3_bucket}.s3.amazonaws.com/site/homepage/music/Country/guitar.mp3`,
      ],
    ],
  },
];

const StyledMenuItem = styled(MenuItem)(
    ` 
      &:hover, &.Mui-focusVisible, &.Mui-selected {
        background-color: #fb8c00
      }`
    );
  
function Home() {
  const location = useLocation();
  const [selectedGroup, setSelectedGroup] = useState(0);

  useEffect(() => {
    if (location.hash) {
      const targetElement = document.querySelector(location.hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
  };

  return (
    <>
      <MKBox bgColor="black" p={2}>
        <MKBox minHeight="40vh" width="100%" />
        <Banner />
        <MKBox id="features">
          <Features />
        </MKBox>
        <MKBox
          sx={{ border: "1px solid #fb8c00", borderRadius: "10px", mt: 15 }}
        >
          <MKTypography
            align="center"
            variant="h3"
            sx={{ color: "warning.main", mt: 5, mb: 3 }}
          >
            Listen for yourself!
          </MKTypography>
          <MKBox sx={{display: "flex", flexDirection: "row", alignItems: "center", mb: 1}}>
          <MKTypography variant="h5" align="left" ml={3} color="white">Genre:</MKTypography>
          <Select 
            value={selectedGroup} 
            onChange={handleGroupChange} 
            sx={{ ml: {xs: 1, sm: 1},  
              "& .MuiInputBase-input.MuiOutlinedInput-input": {
                color: "white.main",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "primary.main", // Set border color to primary color
              },
            }}
            MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: "#0077ff !important",
                    "& .MuiList-root": {
                      padding: 0
                    }
                  }
                }
              }}
                  >
              {HomepageMusic.map((group, index) => (
                <StyledMenuItem key={index} value={index} color="white">
                  {group.metadata[0].genre}
                </StyledMenuItem>
              ))}
            </Select>
            </MKBox>
          <MKBox sx={{ padding: "20px", mb: 1 }}>
            <MultitrackPlayer metaData={HomepageMusic[selectedGroup].metadata[0]} attributionUrl={HomepageMusic[selectedGroup].attribution} stemUrls={HomepageMusic[selectedGroup].stemUrls[0]} />
          </MKBox>
        </MKBox>
        <MKBox sx={{ mt: 15 }}>
          <Testimonials />
        </MKBox>
        <div id="pricing"></div>
        <MKBox sx={{ mt: 10, mb: 5 }}>
          <Pricing />
        </MKBox>
        <Divider sx={{ border: "1px solid #0077ff", opacity: "1.0", mt: 10 }} />
      </MKBox>
      <MKBox sx={{ mt: 5 }}>
        <Footer />
      </MKBox>
    </>
  );
}

export default Home;
