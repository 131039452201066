import React, { useState } from "react";
import { Storage } from "aws-amplify";
import { Box, Button, Grid, Typography } from "@mui/material";
import QuotaExceededDialog from "./Dialogs/QuotaExceeded";
import { useTheme } from "@mui/material/styles";
import FileDropzone from "./FileDropZone";
import FileList from "./FileList";
import { GetFileUploadS3key, areDictionariesEqual, removeFileExtension } from "utils";
import MKProgress from "./MKProgress";
import MKTypography from "./MKTypography";
import jsmediatags from "jsmediatags-web";
import { useDispatch, useSelector } from 'react-redux';
import { addAudioFile } from '../redux/slices/AudioFilesSlice';
import LibraryInfoCard from "./Cards/LibraryInfoCard";
import { areUploadsAllowed } from "APIHelpers";

const S3Uploader = () => {
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const dispatch = useDispatch();
  const fileList = useSelector((state) => state.audioFiles);

  // Check each file to ensure it is a supported type (audio or video) and not a duplicate
  const handleDrop = async (acceptedFiles) => {
    const newFiles = [];

    for (const file of acceptedFiles) {
      const isSupportedType = file.type.startsWith("audio/") || file.type.startsWith("video/");
      const isDuplicate = files.some((f) => f.name === file.name && f.size === file.size);

      if (isSupportedType && !isDuplicate) {
        const metadata = await getFileMetadata(file);

        if (metadata) {
          const metadataFields = {
            title: encodeURIComponent(metadata?.tags?.title) || removeFileExtension(file.name) || removeFileExtension(file.key),
            artist: encodeURIComponent(metadata?.tags?.artist) || "UnknownArtist",
            album: encodeURIComponent(metadata?.tags?.album) || "UnknownAlbum",
            track_number: encodeURIComponent(metadata?.tags?.track),
            year: encodeURIComponent(metadata?.tags?.year),
            genre: encodeURIComponent(metadata?.tags?.genre),
            composer: encodeURIComponent(metadata?.tags?.TCOM?.data),
            publisher: encodeURIComponent(metadata?.tags?.TPUB?.data),
          };

          const validMetadataFields = Object.keys(metadataFields).reduce((acc, key) => {
            if (metadataFields[key]) {
              acc[key] = metadataFields[key];
            }
            return acc;
          }, {});

          file.metadata = validMetadataFields;
          newFiles.push(file);
        }
      }
    }

    // Use functional update to ensure state updates correctly
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  // Read metadata tags and encode them
  const getFileMetadata = async (file) => {
    try {
      const metadata = await new Promise((resolve, reject) => {
        jsmediatags.read(file, {
          onSuccess: resolve,
          onError: reject,
        });
      });
      return metadata; // Return the audio metadata
    } catch (error) {
      console.error(`Error processing file: ${file}`, error);
    }
  };

  const handleDelete = (deletedFile) => {
    setFiles((prevFiles) =>
      prevFiles.filter(
        (file) => !areDictionariesEqual(file.metadata, deletedFile.metadata)
      )
    );
  };

  const onClose = () => {
    // Handle user cancellation
    setOpen(false);
  };

  const reduxFileObject = (originalObject, s3key) => {
    const { size, metadata } = originalObject;
    const { genre, artist, album, track_number, title } = metadata;

    return {
      key: s3key,
      size: size,
      metadata: {
        genre: genre,
        artist: artist,
        album: album,
        track_number: track_number,
        title: title,
      },
      separations: {}
    };
  };

  const handleUpload = async () => {
    try {
      if (await areUploadsAllowed()) {
        setUploading(true);
        setProgress(0);

        // Track progress of all uploads
        const progressMap = new Map();

        // Use Promise.all() to await all upload Promises
        const uploadPromises = files.map((file) => {
          return new Promise(async (resolve, reject) => {
            try {
              const s3Key = GetFileUploadS3key(file);
              const result = await Storage.put(s3Key, file, {
                metadata: file.metadata,
                contentType: file.type,
                level: "private",
                progressCallback(progress) {
                  const { loaded, total } = progress;
                  const fileProgress = (loaded / total) * 100;
                  progressMap.set(file.name, fileProgress);
                  const overallProgress = Array.from(progressMap.values()).reduce(
                    (sum, p) => sum + p,
                    0
                  ) / files.length;
                  setProgress(overallProgress);
                },
              });

              // if file upload succeeded, add file info to Redux store
              dispatch(addAudioFile(reduxFileObject(file, s3Key)));

              resolve(result);
            } catch (error) {
              console.error(error);
              reject(error);
            }
          });
        });
        await Promise.all(uploadPromises);
      }
      else {
        // false is actually returned for any server-side error, but assume it's a bandwidth/libary (403) quota issue.
        setOpen(true); // Open QuotaExceededDialog
      }
    } catch (error) {
      console.error(error);
    } finally {
      // Regardless of success or error, reset the state
      setUploading(false);
      setFiles([]);
      setProgress(0);
    }
  };

  return (
    <Box sx={{ height: "100vh", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Grid
        container
        direction="column"
        sx={{
          width: { xs: "80vw", md: "60vw" },
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Grid
          item
          sx={{
            height: '20vh',
            display: 'flex',
            alignItems: 'flex-end',
            width: '100%',
          }}
        >
          <Typography variant="h3" color="primary" align="center" pb={2} width="100%">
            Upload
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "5px",
            marginBottom: "20px",
            height: "30vh",
            width: "100%",
          }}
        >
          <FileDropzone onDrop={handleDrop} />
        </Grid>
        <Grid item sx={{ maxHeight: "30vh", width: "100%", overflow: "auto" }}>
          <FileList files={files} onClick={() => {}} onDelete={handleDelete} />
        </Grid>
        {files.length !== 0 && (
          <Grid item>
            <Box mt={5} p={3} display="flex" justifyContent="center">
              <Button
                onClick={handleUpload}
                disabled={files.length === 0 || uploading}
                style={theme.SoundShatterButton}
                variant="contained"
              >
                <MKTypography sx={{ color: "white.main" }}>
                  Upload {files.length} {files.length === 1 ? 'file' : 'files'}
                </MKTypography>
              </Button>
            </Box>
          </Grid>
        )}
        <Grid item sx={{ width: "100%" }}>
          {uploading && (
            <Box>
              <MKTypography align="center">Uploading!</MKTypography>
              <MKProgress mt={2} color="primary" value={progress} />
            </Box>
          )}
        </Grid>
        <Grid
          item
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            width: "100%",
            mb: { xs: 0, sm: 5 },
          }}
        >
          {files.length === 0 && (
            <LibraryInfoCard
              title="Library Usage"
              description="Information about your current library usage"
              direction="center"
              fileList={fileList}
            />
          )}
        </Grid>
      </Grid>
      <QuotaExceededDialog open={open} onClose={onClose} />
    </Box>
  );
  
};

export default S3Uploader;
