import PropTypes from "prop-types";
import { useEffect, useState} from "react";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Box } from "@mui/material";
import { getLibraryUsage } from "utils";

function LibraryInfoCard({ color, title, description, direction, small, fileList }) {
  const [libraryUsageInfo, setLibraryUsageInfo] = useState(null);

  useEffect(() => {
    async function fetchLibrarySize() {
      try {
        const usage = await getLibraryUsage(fileList);
        setLibraryUsageInfo(usage);
      } catch (error) {
        console.error("Error fetching library size:", error);
      }
    }
    
    fetchLibrarySize();
  }, [fileList]);

  return (
    <MKBox lineHeight={1} p={direction === "center" ? 2 : 0} textAlign={direction}>
       <MKTypography
        display="block"
        variant="h3"
        color={color}
        fontWeight="bold"
        mt={direction === "center" ? 1 : 2}
        mb={1.5}
      >
        {title}
      </MKTypography>
      <MKTypography
        display="block"
        variant={small ? "button" : "body2"}
        color="text"
        pr={direction === "left" ? 6 : 0}
        pl={direction === "right" ? 6 : 0}
      >
        {description}
      </MKTypography>
      <Box
  sx={{
    display: "flex",
    flexDirection: "column",
    mt: 5,
    backgroundColor: "primary.main",
    borderRadius: "8px", // Adjust the value as needed
    p: 2, // Add padding to create some space around the content
  }}
>
  <Box>
    <MKTypography variant="h5" color="white">
      Uploaded files: {libraryUsageInfo && libraryUsageInfo.TotalUploadsSize} Mb
    </MKTypography>
  </Box>
  <Box>
    <MKTypography variant="h5" color="white">
      Created files: {libraryUsageInfo && libraryUsageInfo.TotalOutputSize} Mb
    </MKTypography>
  </Box>
  <Box>
    <MKTypography variant="h5" color="white">
      Total library size: {libraryUsageInfo && libraryUsageInfo.TotalLibrarySize} Mb
    </MKTypography>
  </Box>
</Box>
    </MKBox>
  );
}

LibraryInfoCard.defaultProps = {
  color: "info",
  direction: "left",
  small: false,
};

LibraryInfoCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(["left", "right", "center"]),
  small: PropTypes.bool,
};

export default LibraryInfoCard;
