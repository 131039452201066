import React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
} from "@mui/material";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { Link as RouterLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import WbIridescentIcon from "@mui/icons-material/WbIridescent";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { logout } from "../redux/slices/actions";
import { persistor } from "../redux/store";

const unauthPages = [
  { name: "Features", path: "/Home#features" },
  { name: "Pricing", path: "/Home#pricing" },
  { name: "News", path: "/News" },
];

const authPages = [
  { name: "Upload", path: "/Upload" },
  { name: "Library", path: "/Library" },
];

const settings = [
  { label: "Profile", path: "/profile" },
  { label: "Account", path: "/account" },
  { label: "Attributions", path: "/Attributions" },
  { label: "Privacy", path: "/Privacy" },
  { label: "Terms of Use", path: "/TermsOfUse" },
];

async function SignOutUser(dispatch) {
    try {
      dispatch(logout());           // remove user data from redux cache when signout occurs
      persistor.purge();            // remove user from redux-persist store as well
      await Auth.signOut();         // Sign out the user and await completion
      return;
    } catch (error) {
      console.log(JSON.stringify(error));
      throw error;
    }
  }
  
function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const { user } = useAuthenticator((context) => [context.user]);
  const dispatch = useDispatch();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar color="primary" position="absolute" sx={{ minHeight: "72px" }}>
      <Toolbar
        sx={{ paddingX: { xs: "0%", md: "15%" }, flexGrow: 1 }}
        disableGutters
      >
        <WbIridescentIcon
          sx={{
            color: "white.main",
            display: { xs: "none", md: "flex" },
            mr: 1,
          }}
        />

        <Typography
          variant="h5"
          noWrap
          component={RouterLink}
          to="/Home"
          sx={{
            mr: 2,
            display: { xs: "none", md: "flex" },
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            color: "white.main",
            textDecoration: "none",
          }}
        >
          SoundShatter
        </Typography>

        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            sx={{ color: "white.main" }}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {(!user ? unauthPages : unauthPages.concat(authPages)).map(
              (page) => (
                <MenuItem
                  key={page.name}
                  onClick={handleCloseNavMenu}
                  to={page.path}
                  component={RouterLink}
                >
                  <Typography color="dark.main" textAlign="center">
                    {page.name}
                  </Typography>
                </MenuItem>
              )
            )}
          </Menu>
        </Box>
        <Typography
          variant="h6"
          noWrap
          component={RouterLink}
          to="/Home"
          sx={{
            mr: 2,
            display: { xs: "flex", md: "none" },
            flexGrow: 1,
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: { xs: ".2rem", md: ".3rem" },
            color: "inherit",
            textDecoration: "none",
          }}
        >
          SoundShatter
        </Typography>
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {unauthPages.map((page) => (
            <Button
              key={"unauth_" + page.name}
              sx={{ alignContent: "center", color: "white", display: "block" }}
            >
              <Typography
                key={page.name}
                onClick={handleCloseUserMenu}
                component={RouterLink}
                to={page.path}
                sx={{ color: "white.main" }}
                variant="button"
                textAlign="center"
              >
                {page.name}
              </Typography>
            </Button>
          ))}
        </Box>

        {!user ? (
          <Button
            key="SignIn"
            onClick={() => {
              setAnchorElUser(null);
            }}
            component={RouterLink}
            to="/Upload"
            sx={{
              alignContent: "center",
              color: "white",
              display: "block",
              px: 1,
            }}
          >
            <Typography
              variant="button"
              sx={{ color: "white.main", mr:1 }}
              textAlign="center"
            >
              Sign in
            </Typography>
          </Button>
        ) : (
          <>
            {authPages.map((page) => (
              <Button
                key={page.name}
                onClick={handleCloseUserMenu}
                component={RouterLink}
                to={page.path}
                sx={{
                  color: "white",
                  display: { xs: "none", md: "block" },
                }}
              >
                <Typography
                  variant="button"
                  textAlign="center"
                  sx={{ color: "white.main" }}
                >
                  {page.name}
                </Typography>
              </Button>
            ))}
          </>
        )}

        {user ? (
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ pr: "10px" }}>
                <Avatar alt="Remy Sharp" sx={{ backgroundColor: "#fb8c00" }} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="settings-menu"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting.label}
                  onClick={handleCloseUserMenu}
                  component={RouterLink}
                  to={setting.path}
                >
                  <Typography sx={{ color: "dark.main" }} textAlign="center">
                    {setting.label}
                  </Typography>
                </MenuItem>
              ))}
              <Authenticator>
    {({ user }) => (
      <MenuItem
        key="SignOut"
        onClick={async () => {
          setAnchorElUser(null);          // Close the menu
          await SignOutUser(dispatch);    
        }}
        component={RouterLink}
        to="/Home"
      >
        <Typography sx={{ color: 'dark.main' }} textAlign="center">
          Sign out
        </Typography>
      </MenuItem>
    )}
  </Authenticator>
            </Menu>
          </Box>
        ) : (
          <Button
            key="GetStarted"
            variant="contained"
            onClick={() => {
              setAnchorElUser(null);
            }}
            component={RouterLink}
            to="/Home#pricing"
            sx={{
              backgroundColor: "#fb8c00",
              alignContent: "center",
              px: 1,
              mr: 1,
            }}
          >
            <Typography
              variant="button"
              sx={{ color: "white.main", fontSize: { xs: "9px", md: "12px" } }}
            >
              Get Started!
            </Typography>
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default ResponsiveAppBar;
