import { Storage } from "aws-amplify";
import { SignInUser, getFileProperties } from "utils";
import { AudioUploadPath, AudioSeparationPath } from "const";
import { addAudioFile, addSeparation } from "./redux/slices/AudioFilesSlice";

export async function SyncAllFromCloud(dispatch, sub) {
  await SignInUser(dispatch, sub);
  await SyncLibrary(dispatch);
}

async function SyncLibrary(dispatch) {
  try {
    const audioFileUploadList = await Storage.list(`${AudioUploadPath}`, {
      level: "private",
      pageSize: "ALL",
    });

    const allDispatchActions = [];

    const uploadPromises = audioFileUploadList.results.map(async (audioFileUpload) => {
      const fileProperties = await getFileProperties(audioFileUpload.key);
      const { artist, album, title } = fileProperties.metadata;

      const audioFileAction = addAudioFile(
        newReduxAudioObject(audioFileUpload, fileProperties.metadata)
      );
      allDispatchActions.push(audioFileAction);

      const separationFileList = await Storage.list(
        `${AudioSeparationPath}${artist}/${album}/${title}`,
        { level: "private", pageSize: "ALL" }
      );

      const groupedStems = groupStemsByModelAndMethod(separationFileList.results);
      groupedStems.forEach((stems) => {
        const separation = newReduxSeparationObject(audioFileUpload.key, stems);
        const separationKey = getSeparationKey(stems);
        allDispatchActions.push(
          addSeparation({
            type: "ADD_SEPARATION",
            newSeparation: { separationKey, separation },
          })
        );
      });
    });

    await Promise.all(uploadPromises);
    allDispatchActions.forEach(dispatch);
  } catch (error) {
    console.error(`SyncLibrary Error: ${JSON.stringify(error)}`);
    throw error;
  }
}

function groupStemsByModelAndMethod(separationsList) {
  const separations = separationsList.reduce((acc, file) => {
    const parts = file.key.split("/");
    const groupKey = `${parts[7]}:${parts[8]}`;

    if (!acc[groupKey]) acc[groupKey] = [];
    acc[groupKey].push(file);

    return acc;
  }, {});

  return Object.values(separations);
}

const newReduxAudioObject = (upload, metadata) => ({
  key: upload.key,
  size: upload.size,
  metadata: {
    genre: metadata.genre,
    artist: metadata.artist,
    album: metadata.album,
    track_number: metadata.track_number,
    title: metadata.title,
  },
  separations: {},
});

function getSeparationKey(stems) {
  return stems.length > 0
    ? stems[0].key.split("/").slice(0, -1).join("/")
    : null;
}

function newReduxSeparationObject(sourceKey, stems) {
  const [method, model] = stems[0]?.key.split("/").slice(7, 9) || [];

  return stems.length
    ? {
        sourceKey,
        stems: stems.map(({ key, size, eTag, lastModified }) => ({
          key,
          size,
          eTag,
          lastModified: lastModified.toISOString(),
        })),
        method,
        model,
        status: "Complete",
        taskArn: null,
        zipKey: null,
        size: stems.reduce((acc, { size }) => acc + size, 0),
      }
    : null;
}
