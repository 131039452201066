import React, { useState } from "react";
import { Box, Stack, Typography, Tooltip } from "@mui/material";
import "./FileList.css"; // import CSS file for styling
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDeleteTrackDialog from "./Dialogs/ConfirmDeleteTrackDialog";
import { Storage } from "aws-amplify";
import { AudioSeparationPath } from "const";

const FileList = ({ files, onDelete, onClick }) => {
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [separations, setSeparations] = useState(false);

  // Handles confirmation of delete file dialog box.
  const onConfirm = async () => {
    try {
      await onDelete(selectedFile, separations);

      // Close dialog upon confirmation.
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  function customFileSort(a, b) {
    // Custom sorting logic based on file metadata
    const artistA = decodeURIComponent(a.metadata.artist || '').toLowerCase();
    const artistB = decodeURIComponent(b.metadata.artist || '').toLowerCase();
    const titleA = decodeURIComponent(a.metadata.title || '').toLowerCase();
    const titleB = decodeURIComponent(b.metadata.title || '').toLowerCase();

    if (artistA !== artistB) {
      return artistA.localeCompare(artistB);
    }
    
    return titleA.localeCompare(titleB);
  }

  // Handle cancellation of delete file dialog box
  const onCancel = () => {
    setOpen(false);
  };

  // Handles clicks on delete icon
  const onDeleteClick = async (event, file) => {
    event.stopPropagation();

    try {
      const path = `${AudioSeparationPath}${file.metadata.artist}/${file.metadata.album}/${file.metadata.title}`;
      const separationsList = await Storage.list(path, {
        level: "private",
        pageSize: "ALL",
      });

      setSeparations(separationsList.results);
      setSelectedFile(file);
      setOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  // Sort files based on custom sorting logic
  const sortedFiles = Object.values(files).sort(customFileSort);

  return (
    <Stack sx={{ flexGrow: 1 }}>
      <Stack spacing={3}>
        {sortedFiles.map((file) => (
          <Box
            key={file.key}
            className="file-list-item"
            sx={{ flex: 1, display: "flex", alignItems: "center", p:2, borderRadius: 4, boxShadow: 1, cursor: "pointer" }}
            onClick={(event) => onClick(event, file)}
          >
            <Box flexGrow={1} sx={{ width: "60%" }}>
              <Typography variant="body1">
                {decodeURIComponent(file.metadata.artist)} -{" "} {decodeURIComponent(file.metadata.title)}
              </Typography>
            </Box>
            <Box flexGrow={1} textAlign="center">
              <Typography variant="body1">
                {Math.round((file.size / (1024 * 1024)) * 10) / 10} Mb
              </Typography>
            </Box>
            <Box textAlign="right">
              <Tooltip title="Delete">
                <DeleteIcon
                  color="primary"
                  onClick={(event) => onDeleteClick(event, file)} // Pass the file object
                />
              </Tooltip>
            </Box>
          </Box>
        ))}
        <ConfirmDeleteTrackDialog
          open={open}
          onClose={onCancel}
          onConfirm={onConfirm}
          file={selectedFile}
          separations={separations}
        />
      </Stack>
    </Stack>
  );
};

export default FileList;
