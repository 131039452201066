// utils.js
import { Auth, Storage, API } from "aws-amplify";
import { AudioSeparationPath, AudioUploadPath, MbSize } from "const";
import { updateSeparation } from "./redux/slices/AudioFilesSlice";
import { signIn } from "./redux/slices/UserSlice";
  
// useful function that may be needed someday
export const GetKeyFromFile = async (file) => {
  return `private/${await GetCognitoIdentityId()}/${file.key}`;
};

// File drag/drops and AWS disagree on what to call the file name.  ;)
export function GetFileName(file) {
  return file.metadata?.title || file.name || file.key;
}

export function removeFileExtension(filename) {
  if (!filename || typeof filename !== "string") {
    return filename; // Return input if it's empty, null, or not a string
  }

  const lastDotIndex = filename.lastIndexOf(".");
  if (lastDotIndex === -1) {
    return filename; // No extension found
  }
  return filename.substring(0, lastDotIndex);
}

export function GetFileBaseNameFromPath(filePath) {
  if (!filePath.includes("/")) {
    return filePath;
  }

  const startIndex = filePath.lastIndexOf("/") + 1;
  const endIndex = filePath.lastIndexOf(".");
  return filePath.substring(startIndex, endIndex);
}

export function GetFileNameFromPath(filePath) {
  const startIndex = filePath.lastIndexOf("/") + 1;
  return filePath.substring(startIndex, filePath.length);
}

export function GetStemFiles(separationList) {
  const stemFiles = [];
  for (const key in separationList) {
    if (separationList.hasOwnProperty(key)) {
      const stems = separationList[key].stems;
      for (const stem of stems) {
        stemFiles.push(stem);
      }
    }
  }
  return stemFiles;
}

function assertMapHasKey(parameterMap, requiredKey) {
  if (
    typeof parameterMap !== "object" ||
    parameterMap === null ||
    Array.isArray(parameterMap)
  ) {
    throw new Error("Parameter must be a non-null object.");
  }

  if (!parameterMap.hasOwnProperty(requiredKey)) {
    throw new Error(`Parameter must contain the key "${requiredKey}".`);
  }
}

export async function getFileProperties(key) {
  try {
    return await Storage.vault.getProperties(key);
  } catch (error) {
    console.log("Error ", error);
    throw error;
  }
}

export async function SignInUser(dispatch, sub) {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const cognitoIdentityId = await GetCognitoIdentityId();
    const postData = {
      identityEmail: user.attributes.email,
    };

    const response = await API.post(
      "soundshatterapi",
      `/users/${cognitoIdentityId}/auth/signin`,
      {
        body: postData,
      }
    );

    dispatch(
      signIn({
        type: "USER_SIGNIN",
        key: sub,
        user: response.body,
      })
    );

    return;
  } catch (error) {
    console.log(JSON.stringify(error));
    throw error;
  }
}

/****************************************************
 * Validates args and generates an S3 key for a file upload
 */
export function GetFileUploadS3key(file) {
  assertMapHasKey(file.metadata, "artist");
  assertMapHasKey(file.metadata, "album");
  assertMapHasKey(file.metadata, "title");

  const extIndex = file.name.lastIndexOf(".");
  const fileExtension = file.name.substring(extIndex);
  return `${AudioUploadPath}${file?.metadata?.artist}/${file?.metadata?.album}/${file?.metadata?.title}${fileExtension}`;
}

export function GetFileBasename(file) {
  assertMapHasKey(file.metadata, "title");
  const { title } = file.metadata;
  const extIndex = title.lastIndexOf(".");
  return extIndex === -1 ? title : title.substring(0, extIndex);
}

export function GetSeparationKey(metadata) {
  assertMapHasKey(metadata, "artist");
  assertMapHasKey(metadata, "album");
  assertMapHasKey(metadata, "title");

  return `${AudioSeparationPath}${metadata?.artist}/${metadata?.album}/${metadata?.title}`;
}

export async function GetPresignedUrlForFile(key, scope) {
  try {
    const presignedUrl = await Storage.get(key, {
      level: scope,  
      expires: 3600,
    });
    return presignedUrl;
  } catch (error) {
    console.error("Error retrieving presigned URL for file:", error);
    return null;
  }
}

/********************************************************
 * Determines if the specifiec ECS task is still running
 ********************************************************/
export async function IsTaskRunning(arn) {
  try {
    const apiPath = `/tasks/${encodeURIComponent(arn)}/status`;
    const results = await API.post("soundshatterapi", apiPath);

    return results.success && results.data.body === "RUNNING";
  } catch (error) {
    return false;
  }
}

function getZipFileKey(stems) {
    if (!stems || stems.length === 0) return null;
  
    const zipStem = stems.find(stem => stem.key.toLowerCase().endsWith('.zip'));
    return zipStem ? zipStem.key : null;
  };


export const reduxSeparationObject = (
    separationKey,
    stems,
    sourceKey,
    status,
    taskArn
  ) => {
    const size = stems.reduce(
      (acc, stem) => acc + stem.size,
      0
    );
    const keyParts = separationKey.split("/");
    const zipKey = getZipFileKey(stems);

    return {
      sourceKey: sourceKey,
      stems: stems,
      method: keyParts[keyParts.length - 2],
      model: keyParts[keyParts.length - 1],
      status: status,
      taskArn: taskArn,
      zipKey: zipKey,
      size: size,
    };
  };

  export async function UpdatePendingSeparation(file, pendingSeparation, dispatch) {
    const rootSeparationKey = GetSeparationKey(file.metadata);
  
    // Construct the S3 key for the pending separation
    const pendingSeparationKey = `${rootSeparationKey}/${pendingSeparation.method}/${pendingSeparation.model}`;
  
    // Retrieve stem information from S3
    const { results: stems } = await Storage.list(pendingSeparationKey, {
      level: "private",
      pageSize: "ALL",
    });
  
    // Transform stems to include only necessary properties and format lastModified as ISO string
    const filteredStems = stems.map(stem => ({
      key: stem.key,
      size: stem.size,
      eTag: stem.eTag,
      lastModified: stem.lastModified.toISOString(),
    }));
  
    // Create the separation object to be used to update the existing pending separation
    const separation = reduxSeparationObject(
      pendingSeparationKey,
      filteredStems,
      file.key,
      "Complete",
      null
    );
  
    // Dispatch the update separation action
    dispatch(
      updateSeparation({
        type: "UPDATE_SEPARATION",
        newSeparation: {
          pendingSeparationKey,
          separation,
        },
      })
    );
  }
  
/**********************************************************
 * Get the cognito ID of the currently authenticated user
 **********************************************************/
export async function GetCognitoIdentityId() {
  try {
    // Ensure the user is signed in
    const currentUser = await Auth.currentAuthenticatedUser();

    // Retrieve Cognito Identity ID from the current AWS credentials
    const currentCredentials = await Auth.currentCredentials();
    return currentCredentials.identityId;
  } catch (error) {
    console.log("Error:", error);
  }
}

export function areDictionariesEqual(dict1, dict2) {
  const keys1 = Object.keys(dict1);
  const keys2 = Object.keys(dict2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check if all keys are the same
  const allKeysEqual = keys1.every((key) => keys2.includes(key));
  if (!allKeysEqual) {
    return false;
  }

  // Check if values for all keys are equal
  const allValuesEqual = keys1.every((key) => dict1[key] === dict2[key]);
  if (!allValuesEqual) {
    return false;
  }

  return true;
}

export async function getLibraryUsage(files) {
  let totalUploadsSize = 0;
  let totalOutputSize = 0;

  for (const fileKey in files) {
    const file = files[fileKey];
    totalUploadsSize += file.size;

    for (const separationKey in file.separations) {
      const separation = file.separations[separationKey];
      totalOutputSize += separation.size;
    }
  }

  return {
    TotalUploadsSize: (totalUploadsSize / MbSize).toFixed(1),
    TotalOutputSize: (totalOutputSize / MbSize).toFixed(1),
    TotalLibrarySize: ((totalUploadsSize + totalOutputSize) / MbSize).toFixed(
      1
    ),
  };
}
