import { React, useRef, useState, useEffect, useCallback } from "react";
import { Box } from "@mui/material";

function PlayCursor(props) {
  const { audioPosition, playbackCommand, onDrop } = props;
  const cursorRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  function handleMouseDown() {
    setIsDragging(true);
  }

  function handleMouseUp(event) {
    setIsDragging(false);
    const progress = updateDragPosition(event);
    onDrop(progress);
  }

  const updateDragPosition = useCallback((event) => {
    if (isDragging) {
      const parent = cursorRef.current.parentNode;
      const containerRect = parent.getBoundingClientRect();
      const positionX = event.pageX - containerRect.left;
      let progress = positionX / containerRect.width;
  
      // Constrain the position of the play cursor to the waveform or timeline
      if (progress < 0) {
        progress = 0;
      } else if (progress > 1) {
        progress = 1;
      }
  
      cursorRef.current.style.left = `${progress * 100}%`;
      return progress;
    }
  }, [isDragging]);

  useEffect(() => {
    if (playbackCommand === "reset") {
      cursorRef.current.style.left = `${-0.15}%`;
    }
  }, [playbackCommand]);

  useEffect(() => {
    // Should automatically track the position of the tracks as they are played, unless it's being dragged.
    if (!isDragging) {
      cursorRef.current.style.left = `${(audioPosition * 100)-0.15}%`;
    }
  }, [audioPosition, isDragging]);

  useEffect(() => {
    const handleMove = (event) => {
      updateDragPosition(event);
    };

    const handleUp = (event) => {
      updateDragPosition(event);
      setIsDragging(false);
      document.removeEventListener("mousemove", handleMove);
      document.removeEventListener("mouseup", handleUp);
    };

    document.addEventListener("mousemove", handleMove);
    document.addEventListener("mouseup", handleUp);

    return () => {
      document.removeEventListener("mousemove", handleMove);
      document.removeEventListener("mouseup", handleUp);
    };
  }, [isDragging, updateDragPosition]);

  return (
    <Box
      className="play-cursor"
      sx={{
        position: "absolute",
        top: "0%",
        bottom: "0%",
        width: ".2%",
        backgroundColor: "primary.main",
        zIndex: "10",
        cursor: "pointer"}}
      ref={cursorRef}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    />
  );
}

export default PlayCursor;
