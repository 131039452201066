import React from "react";
import S3Uploader from "../S3Uploader";
import { Box } from "@mui/material";
import CustomAuthenticator from "components/CustomAuthenticator";

function Upload() {
  return (
    <CustomAuthenticator>
    <Box className="App-body" sx={{ justifyContent: 'flex-start' }}>
      <S3Uploader />
    </Box>
    </CustomAuthenticator>
  );
}

export default Upload;
