import React from "react";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Grid,
} from "@mui/material";

function Pricing() {
  const primaryTextStyle = {
    color: "primary",
    fontSize: "1.5rem",
    marginBottom: "0.5rem", // Added marginBottom
  };

  const secondaryTextStyle = {
    color: "secondary",
  };

  return (
    <Box className="App-body">
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8}>
          {" "}
          {/* Adjusted the grid item size */}
          <Typography
            align="center"
            variant="h4"
            color="primary"
            sx={{ marginBottom: "1rem" }}
          >
            Pricing
          </Typography>
          <List sx={{ marginBottom: "2rem" }}>
            <ListItem disablePadding>
              <ListItemText
                primaryTypographyProps={primaryTextStyle}
                secondaryTypographyProps={secondaryTextStyle}
                primary="Free tier"
                secondary={
                  <ul>
                    <li>
                      Experience the power of SoundShatter with our free
                      introductory tier
                    </li>
                    {/* Add bullet points for each list item */}
                    <li>
                      Enjoy several sound separations each month at no cost
                    </li>
                    <li>Get access to a 1GB library to store your files</li>
                    <li>
                      Choose from a selection of high-quality separation methods
                      and models
                    </li>
                    <li>File output format limited to .mp3</li>
                  </ul>
                }
              />
            </ListItem>
            {/* Repeat the same formatting for other tiers */}
            <ListItem disablePadding>
              <ListItemText
                primaryTypographyProps={primaryTextStyle}
                secondaryTypographyProps={secondaryTextStyle}
                primary="Paid tiers"
                secondary={
                  <ul>
                    <li>
                      Elevate your SoundShatter experience with our exclusive
                      premium offerings!
                    </li>
                    <li>
                      Enjoy a generous number of sound separations each month,
                      providing nearly endless possibilities for your audio
                      projects and activities.
                    </li>
                    <li>
                      Gain access to premium AI methods and models for superior
                      sound separation results
                    </li>
                    <li>
                      Automatically get faster results through the application
                      of increased compute power!
                    </li>
                    {/* Add bullet points for each list item */}
                    <li>
                      Store your files conveniently with a larger 10GB library
                      and extended file expiration times.
                    </li>
                    <li>
                      Support for additional file output formats, including
                      lossless formats such as .wav, .flac, .ogg
                    </li>
                    <li>
                      Priority customer support and access to new features and
                      updates
                    </li>
                    <li>Sign up today for as little as $5 per month!</li>
                  </ul>
                }
              />
            </ListItem>
            {/* Repeat the same formatting for other tiers */}
            <ListItem disablePadding>
              <ListItemText
                primaryTypographyProps={primaryTextStyle}
                secondaryTypographyProps={secondaryTextStyle}
                primary="Buy us a coffee"
                secondary={
                  <ul>
                    <li>
                      Are you just visiting? A new user? Enjoying your
                      experience so far? Maybe you are a long-time user that
                      just wants to show some extra love?
                    </li>
                    <li>
                      If you wish to show your support without committing to a
                      paid tier, you can make a one-time donation of any amount
                      - even $1!
                    </li>
                    <li>
                      While your generous contribution is greatly appreciated,
                      note that it does not grant access to additional features
                      or capabilities.
                    </li>
                    <li>
                      Consider it a way to buy us a coffee and express your
                      support in a different way. It's super encouraging!
                    </li>
                  </ul>
                }
              />
            </ListItem>
          </List>
          <Typography
            variant="body1"
            sx={{ marginBottom: "2rem", fontWeight: "bold" }}
          >
            Join our global community of passionate sound enthusiasts,
            musicians, podcasters, and content creators who rely on SoundShatter
            to take their audio content to the next level. Upgrade to our paid
            tier and unlock a world of possibilities!
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Pricing;
