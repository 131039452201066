/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import waveform from "./waveform.jpg";
import { Link } from "react-router-dom";

function Banner() {
  return (
    <MKBox
      variant="gradient"
      position="relative"
      borderRadius="xl"
      mx={{ xs: 2, xl: 3, xxl: 16 }}
      mt={-30}
      py={10}
      px={3}
      sx={{ backgroundColor: "primary.main", overflow: "hidden" }}
    >
      <MKBox
        component="img"
        src={bgPattern}
        alt="pattern-lines"
        position="absolute"
        top={0}
        left={0}
        width="100%"
        opacity={1}
      />
      <Container sx={{ position: "relative" }}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            item
            xs={12}
            md={7}
            lg={5}
            py={{ xs: 0, sm: 6 }}
            mr="auto"
            position="relative"
          >
            <MKTypography variant="h2" color="warning" mb={1}>
              Hear your music as never before!
            </MKTypography>
            <MKTypography variant="body1" color="white" mb={6}>
              Welcome to SoundShatter, where pristine audio quality meets
              groundbreaking technology, creating an immersive experience like
              no other. Plunge into a captivating world of sonic discovery as
              our AI-powered platform disentangles even the most intricate
              soundscapes, effortlessly isolates vocals, instruments, and other
              elements, and uncovers hidden details that have eluded your ears
              until now.
            </MKTypography>
            <MKBox
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "center",
                marginTop: "10px",
                gap: "15px",
              }}
            >
              <Link to="/home#pricing">
                <MKButton
                  variant="gradient"
                  sx={{
                    backgroundColor: "warning.main",
                    margin: { xs: "0 0px", md: "0 10px" },
                  }}
                >
                  Start now
                </MKButton>
              </Link>
              <Link to="/home#features">
                <MKButton
                  variant="outlined"
                  color="white"
                  sx={{ flex: "1", width: "100%" }}
                >
                  Read more
                </MKButton>
              </Link>
            </MKBox>
          </Grid>
          <Grid
            item
            xs={12}
            position="absolute"
            left={{ xs: "60%", lg: "45%" }}
            mr={-32}
            width="75%"
            display={{ xs: "none", md: "block" }}
          >
            <MKBox component="img" src={waveform} alt="macbook" width="100%" />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Banner;
